import React, { useEffect, useState } from 'react';
import { RiseOutlined } from '@ant-design/icons';
import { STRINGS } from '../../constants/Constant';
import { initiateOrder } from '../../services/orders/order';
import { useSelector } from 'react-redux';
import { productApi } from '../../services/product/productApi';
import { useRef } from 'react';
import Routes from '../../Routes/Routes';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


function PayPalForm({ from, CalculateTotals, couponId }) {

    const placeOrderButtonRef = useRef(null);
    let buy = useSelector((state) => state.buy)||[];
    const newProducts =  buy.map(product => ({
        product_id: product.id,
        product_variant_id:product?.p_variant_id,
        quantity:product?.count,
        amount:product?.price,
    }));
    const urlEncodedBuyNowData =newProducts.length>0? encodeURIComponent(JSON.stringify(newProducts)):[];

    // const cart = useSelector((state) => state.cart);
    const { total = { totalCount: 0, totalPrice: 0 }, disAmount = 0, bagDiscount = 0, grandTotal = 0, newGrandTotal = 0, shippingCharge = 0,newShippingCharges=0, rewardSlice = 0, filteredCartData, firstOrderCouponDiscount = 0 } = CalculateTotals();
    // console.log(newShippingCharges,"xxxxx")

    const productCouponId = JSON.parse(localStorage.getItem('productCoupon'));
    const firstOrderCouponDetail = JSON.parse(localStorage.getItem('FirstOrderCouponDetails'));
    const gst_number = sessionStorage.getItem('Gst_number') ? sessionStorage.getItem('Gst_number') : '';
    // const firstOrderId = firstOrderCouponDetail ? firstOrderCouponDetail :''
    const firstOrderId = firstOrderCouponDetail?.data?.id !== undefined ? firstOrderCouponDetail?.data?.id:''
    const newProductCoupon = productCouponId ? productCouponId : []

    const productWiseCouponId = [];
    filteredCartData?.forEach(cartItem => {
        newProductCoupon.forEach(couponItem => {
            if (cartItem.id === couponItem.productId) {
                productWiseCouponId.push(couponItem.couponId);
            }
        });
    });

    const { id = '', address = '', mobile_number = '', title = '', land_mark = '', city = {}, zip_code = '', name = '', state = {}, country = {} } = from;
    const [orderId, setOrderId] = useState()
    const user = useSelector((state) => state.user);
    const addressName = name.replace(" ", '_')

    const generateRandomNumber = (id) => {
        const randomNumber = Math.floor(Math.random() * 900000) + 100000;
        return randomNumber.toString() + id;
    };


    useEffect(() => {
        const newOrderId = generateRandomNumber().toString() + user?.id;
        setOrderId(newOrderId)
    }, [])
    // const payOrder = async (e) => {
    //     e.preventDefault()
    //     try {
    //         const formData = new FormData();
    //         formData.append('merchant_id', STRINGS?.CC_AVENUE_MERCHANT_ID);
    //         formData.append('order_id', orderId);
    //         formData.append('currency', 'INR');
    //         formData.append('amount', grandTotal);
    //         formData.append('redirect_url', "http://api.growthgrids.com:3011/api/user/ccavenue-callback");
    //         formData.append('cancel_url', "http://api.growthgrids.com:3011/api/user/ccavenue-callback");
    //         formData.append('language', "EN");
    //         formData.append('billing_name', from?.user?.firstname);
    //         formData.append('billing_address', address);
    //         formData.append('billing_city', city?.city_name);
    //         formData.append('billing_state', state?.state_name);
    //         formData.append('billing_zip', zip_code);
    //         formData.append('billing_country', country?.country_name);
    //         formData.append('billing_tel', mobile_number);
    //         formData.append('billing_email', user?.email);
    //         formData.append('delivery_name', user?.lastname);
    //         formData.append('delivery_address', title);
    //         formData.append('delivery_city', land_mark);
    //         formData.append('delivery_state', '');
    //         formData.append('delivery_zip', user?.id);
    //         formData.append('delivery_country', "India");
    //         formData.append('delivery_tel', '');
    //         formData.append('merchant_param1', total?.totalComparePrice);
    //         formData.append('merchant_param2', disAmount);
    //         formData.append('merchant_param3', 0);
    //         formData.append('merchant_param4', bagDiscount);
    //         formData.append('merchant_param5', couponId);
    //         formData.append('integration_type', "iframe_normal");
    //         formData.append('promo_code', '');
    //         formData.append('customer_identifier', 0);
    //         const response = await initiateOrder(formData);
    //         console.log(response, "response")
    //         if (response.status === '1') {
    //         } else {
    //             // console.log('Response', 'Record Not Found !');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching product list :', error);
    //     }

    // }
    // console.log(newShippingCharges,"nnnnnnnnnn")
    const [formData, setFormData] = useState({
        merchant_id: STRINGS?.CC_AVENUE_MERCHANT_ID,
        order_id: generateRandomNumber(user?.id),
        currency: "INR",  // 	
        amount: newGrandTotal, //payment
        redirect_url: `https://payment.kairaus.com/ccavenue-callback?gst_number=${gst_number}&reward_point=${rewardSlice > 0 ? rewardSlice * STRINGS.REWARD_CAL : 0}&reward_amt=${rewardSlice}&address_name=${name}&buy_now=${urlEncodedBuyNowData}&address_id=${id}&first_order_discount_id=${firstOrderId}&first_order_discount_amt=${firstOrderCouponDiscount}`,
        cancel_url: "https://api.kairaus.com/api/user/cancel-payment",
        language: "EN",
        billing_name: from?.user?.firstname,
        billing_address: address,
        billing_city: city?.city_name,
        billing_state: state?.state_name,
        billing_zip: zip_code,
        billing_country: country?.country_name,
        billing_tel: mobile_number,
        billing_email: user?.email,
        delivery_name: user?.lastname,
        delivery_address: title,
        delivery_city: land_mark !== "undefined" ? land_mark : '',
        discount_id: productWiseCouponId,
        delivery_state: "",
        delivery_zip: user?.id,
        delivery_country: "India",
        delivery_tel: "",
        merchant_param1: '',
        merchant_param2: disAmount,
        merchant_param3: grandTotal > 599 ? 0 : newShippingCharges,
        merchant_param4: "0",
        merchant_param5: couponId,
        billing_notes: total?.totalPrice,
        integration_type: "iframe_normal",
        promo_code: "",
        customer_identifier: "2"
    });
    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            amount: newGrandTotal,
            billing_notes: total?.totalPrice,
        }));
    }, [newGrandTotal]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const updatedCartData = buy.length>0?buy:filteredCartData;
    // console.log(updatedCartData)

    const products =  updatedCartData.map(product => ({
        product_id: product.id,
        product_variant_id:product?.p_variant_id,
        quantity:product?.count,
        amount:product?.price,
    }));

    // Checks the Stocks availibilty before placing order.\
    const checkStockAvailibilty = async () => {
        let result = await productApi.stockAvailabilityCheck(products,user?.token);
        if (result?.status === '1') {       // Stock available
            placeOrderButtonRef.current.click();
        }
        else if (result?.status === '2')   // Stock not available
        {
            toast.success(result?.message);
            // navigate(Routes?.Home);

        }
        // else   // No record found
        // {
        //     navigate(Routes?.Home);
           
        // }
    }

    return (
        <>
            {/* <div className='cashDelivery_form'>
                <h2>Pay at your CC Avenue</h2>
                <div className='netBanking_warning'>
                    <RiseOutlined />
                    <span>Click on the button, it will redirect you on paypal page!</span>
                </div>
                <div className='payBtn'>
                    <button type='submit' onClick={()=>payOrder()}>Pay Order </button>
                </div>
            </div> */} 
             {/* https://payment.mystorepedia.com/initiate-payment, https://payment.kairaus.com, https://demo3.mystorepedia.com/ */ }

            <form name="customerData" method="POST" action="https://payment.kairaus.com/initiate-payment">
                <table width="40%" height="100" border='1' align="center" style={{ display: 'none' }}>
                    <tbody>
                        <tr>
                            <td>Parameter Name:</td>
                            <td>Parameter Value:</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Compulsory information</td>
                        </tr>
                        {Object.keys(formData).map((key, index) => (
                            <tr key={index}>
                                <td>{key.replace(/_/g, ' ')}</td>
                                <td>
                                    <input
                                        type="text"
                                        name={key}
                                        value={formData[key]}
                                        onChange={handleChange}
                                    />
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="2"></td>
                        </tr>
                    </tbody>
                </table>
                <div className='cashDelivery_form'>
                    <h2>Pay at your CC Avenue</h2>
                    <div className='netBanking_warning'>
                        <RiseOutlined />
                        <span>Click on the button, it will redirect you on CC Avenue page!</span>
                    </div>
                    <div className='payBtn' style={{ display: 'none' }}>
                        {grandTotal > 0 ? (
                            <td>
                                <button type='submit' value="Checkout" ref={placeOrderButtonRef}>Place Order </button>
                            </td>
                        ) : (
                            <td>
                                <button value="Checkout" disabled style={{ cursor: 'not-allowed' }}>Place Order </button>
                            </td>
                        )}

                    </div>
                </div>


            </form>

            <div className='cashDelivery_form'>

                <div className='payBtn'>
                    <td>
                        <button onClick={() => checkStockAvailibilty()} >Place order </button>

                    </td>
                </div>
            </div>
        </>
    )
}

export default PayPalForm