import React, { useEffect, useState } from "react";
import { Drawer, Form, Input, Select, Row, Col } from "antd";
import { getCountryList } from "../../services/user/getCountryList";
import { bulkOrder } from "../../services/user/addUserAddress";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAddressData } from "../../services/user/getUserAddress";

const { TextArea } = Input;

const BulkDrawer = ({ onClose, openDrawer, productId }) => {
    const [form] = Form.useForm();
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const [showGSTForm, setShowGSTForm] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            if (!user?.token) {
                console.error("User token is not available");
                return;
            }
    
            setLoading(true);
    
            try {
                // Perform both API calls in parallel to reduce wait time
                const [countryResponse, addressResponse] = await Promise.all([
                    getCountryList(),
                    getAddressData(user.token)
                ]);
    
                // Handle country list response
                if (countryResponse?.status === '1') {
                    setCountries(countryResponse?.data || []);
                } else {
                    console.error("Error fetching country list:", countryResponse?.message || "Unknown error");
                }
    
                // Handle user address response
                if (addressResponse?.status === '1') {
                    const defaultAddress = addressResponse.data?.find(address => address.is_default === 1);
                    if (defaultAddress) {
                        form?.setFieldsValue({
                            country: defaultAddress.country.id,
                            number: defaultAddress.mobile_number
                        });
                    }
                } else {
                    console.error("Error fetching user address data:", addressResponse?.message || "Unknown error");
                }
            } catch (error) {
                console.error("Error during data fetching:", error);
            } finally {
                setLoading(false);
            }
        };
    
        if (openDrawer) {
            form?.setFieldsValue({
                name:user?.userfullname,
                email:user?.email,
            });
            fetchData();
        }
    }, [openDrawer, user]);
    
    

    const handleClose = () => {
        onClose(); // Close the drawer
        form.resetFields();
    };

    const handleSubmit = async ({ name, email, number, country, pincode, address, yourquery, gstnumber, companyname, gstaddress }) => {
        if (pincode && pincode.length !== 6) {
            toast.error('Pincode must be a 6-digit number');
            return;
        }
        try {
            const formData = new URLSearchParams();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('mobile_number', number);
            formData.append('country_id', country);
            formData.append('pincode', pincode);
            formData.append('address', address);
            formData.append('query', yourquery);
            if (showGSTForm) { //
                formData.append('gst_no', gstnumber);
                formData.append('gst_comp_name', companyname);
                formData.append('gst_address', gstaddress);
            }
            formData.append('product_id', productId);

            const addResponse = await bulkOrder(formData, user?.token);
            if (addResponse.status === '1') {
                toast.success('Bulk order submitted successfully');
                onClose(); // Close the drawer after successful submission
                form.resetFields();
                setShowGSTForm(false)
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error("Failed to submit form. Please try again later.");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleValidationForMobileAndPincode = (e) => {
        const key = e.key;
        const input = e.target.value;
        if (key === '0' && (input === '' || e.target.selectionStart === 0)) {
            e.preventDefault();
        }
        if (!(key >= '0' && key <= '9') && key !== 'Backspace' && key !== 'Tab' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            // If the pressed key is not a number (0-9), the backspace key, or the tab key
            e.preventDefault(); // Prevent typing
        }
    };

    const handleCheckboxChange = (e) => {
        setShowGSTForm(e.target.checked);
        if (!e.target.checked) {
            form.setFieldsValue({
                gstnumber: '', // Reset GST Number field
                companyname: '', // Reset Company Name field
                gstaddress: '',
            });
            form.validateFields(['gstnumber', 'companyname', 'gstaddress']); // Validate fields after resetting
        }
    };

    return (
        <Drawer
            title="Bulk Orders Inquiry"
            onClose={onClose}
            open={openDrawer}
            className='bulkDrawer_cont'
            width={550}
        >
            <div className='bulkContainer'>
                <Form form={form} onFinish={handleSubmit} autoComplete="off">
                    <div className='model_form'>
                        <Row gutter={18}>
                            <Col sm={24}>
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[{ required: true, message: 'Please Enter Your Name' }]}
                                >
                                    <Input placeholder="Enter Name" />
                                </Form.Item>
                            </Col>
                            <Col sm={24}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { required: true, message: 'Please enter your Email-ID' },
                                        { type: 'email', message: 'Please enter a valid email' },
                                    ]}
                                >
                                    <Input placeholder="Enter Email" onKeyDown={handleKeyPress} />
                                </Form.Item>
                            </Col>
                            <Col sm={24}>
                                <Form.Item
                                    name="number"
                                    label="Phone Number"
                                    rules={[
                                        { required: true, message: 'Please enter your Phone Number' },
                                        {
                                            pattern: /^[0-9]{10}$/,
                                            message: 'Please enter a valid 10-digit phone number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Phone Number" maxLength={10} type='text' onKeyDown={handleValidationForMobileAndPincode} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="country"
                                    label={<>Country</>}
                                    rules={[{ required: true, message: 'Please select your country' }]}
                                    // getPopupContainer={(trigger) => trigger.parentNode}
                                    
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Country"
                                        optionFilterProp="children"
                                        loading={loading}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countries?.sort((a, b) => a.country_name.localeCompare(b.country_name)).map(country => (
                                            <Select.Option key={country.id} value={country.id}>
                                                {country.country_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="pincode"
                                    label="Pincode"
                                    className='counttyDropdown'
                                >
                                    <Input placeholder="Enter Pincode" maxLength={6} onKeyDown={handleValidationForMobileAndPincode} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="address"
                                    label="Address"
                                >
                                    <TextArea placeholder="Enter Road/Area/Colony" rows={3} className='textarea_input' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="yourquery"
                                    label="Your Query"
                                >
                                    <TextArea placeholder="Write Your Query" rows={3} className='textarea_input' />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <div className='gst_cartCont'>
                            <Checkbox onChange={handleCheckboxChange}>Do you want to give your GST information?</Checkbox>
                            {showGSTForm && (
                                <div className='gst_form'>
                                    <Col span={24}>
                                        <Form.Item
                                            name="gstnumber"
                                            label="GST Number"
                                            rules={[
                                                { required: true, message: 'Please enter your GST Number' },
                                                {
                                                    pattern: /^[0-9A-Za-z]{15}$/,
                                                    message: 'Please enter a valid 15-digit GST number',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter GST Number" maxLength={15} type='text' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="companyname"
                                            label="Company Name"
                                            rules={[
                                                { required: true, message: 'Please enter your Company name' },
                                                { type: 'string', message: 'Please enter a valid company name' },
                                            ]}
                                        >
                                            <Input placeholder="Enter your Company name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="gstaddress"
                                            label="Address"
                                            rules={[{ required: true, message: 'Please enter your Address' }]}
                                        >
                                            <Input placeholder="Enter Address" />
                                        </Form.Item>
                                    </Col>
                                </div>
                            )}
                        </div> */}
                    </div>
                    <div className='save_address_button'>
                        <button className='cancelButton' type='button' onClick={handleClose}>Cancel</button>
                        <button className='savedAddressButton' type='submit'>Submit</button>
                    </div>
                </Form>
            </div>
        </Drawer>
    );
};
export default React.memo(BulkDrawer);
