import React, { useState, useRef, useEffect } from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { getCategoriesList, getHomeSliderList, getHomeSectionList, getHaulisList, getHomeColorList, getGiftCategory } from '../../services/home';
import CarouselCom from '../../components/home/CarouselCom';
import { getAboutUs } from '../../services/aboutUs'
import { getBlog } from '../../services/blog'
import AboutUs from '../../components/about/AboutUs'
import ContactUs from '../../components/contact/ContactUs'
import NewArrival from '../../components/home/NewArrival';
import Category from '../../components/home/Category'
import { FaArrowRight } from 'react-icons/fa';
import delivery from '../../assets/images/track.png';
import crdOnline from '../../assets/images/card.png';
import shopWorld from '../../assets/images/worldwide.png';
import experienced from '../../assets/images/experience.png';
import Routes from '../../Routes/Routes';
import { Carousel } from 'react-bootstrap';
import SubscribeImg from '../../assets/images/aboutus/subscribe us 3.jpg';
import { Col, Row } from 'antd';
import asKSvg from '../../assets/images/ask1.png';
import bulksvg from '../../assets/images/ask2.png';
import collsvg from '../../assets/images/ask3.png';
import BestSeller from '../../components/home/BestSeller';
import FinestProduct from '../../components/home/FinestProduct';
import OfferItem from '../../components/home/OfferItem';
import BulkDrawer from '../../components/drawer/BulkDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../reducers/cartSlice';
import { getCart } from '../../services/cart/addCart';


const Home = () => {
  const hasMounted = useRef(false);
  const [sliderList, setSliderList] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [aboutUsData, setAboutUsData] = useState(null);
  const [blogs, setBlog] = useState(null);
  const [haulis, setHaulis] = useState([]);
  const [haulisImagePath, setHaulisImagePath] = useState('');
  const [topBannerImage, setTopBannerImage] = useState(null);
  const [middleBannerImage, setMiddleBannerImage] = useState(null);
  const [downBannerImage, setdownBannerImage] = useState(null);
  const [offerBannerImage, setOfferBannerImage] = useState(null);

  const [colorList, setColorList] = useState([]);
  const [colorImage, setColorImage] = useState([]);
  const [open, setOpen] = useState(false);
  const [topData, setTopData] = useState([]);
  const [middleData, setMiddleData] = useState([]);
  const [downData, setdownData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // contact Us element 
  const [email, setEmail] = useState('');

  const [carouselSpinner, setCarouselSpinner] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const tm = setTimeout(() => {
      sessionStorage.setItem('videoPlay', 'True');
    }, 4000);
    return () => {
      clearTimeout(tm)
    }

  }, [])
  const evetkey = useSelector(state => state.evetkey);
  useEffect(() => {
    if (evetkey.storageKey === 'home') {
      window.scrollTo(0, 0);
    }
  }, []);


  const fetchData = async () => {
    setCarouselSpinner(true);
    try {
      const sliderResponse = await getHomeSliderList();
      if (sliderResponse.status === '1') {
        setSliderList(sliderResponse);
        setCarouselSpinner(false);
      }

      const categoriesResponse = await getCategoriesList();
      if (categoriesResponse.status === '1') {
        setCategoryList(categoriesResponse)
      }

      const aboutUsResponse = await getAboutUs();
      const aboutUsData = aboutUsResponse;
      if (aboutUsResponse.status === '1') {
        setAboutUsData(aboutUsData);
      }

      const blogResponse = await getBlog();
      const blogData = blogResponse;
      if (blogResponse.status === '1') {
        setBlog(blogData);
      }
      const haulisResponse = await getHaulisList();
      if (haulisResponse.status === '1') {
        setHaulis(haulisResponse?.data);
        setHaulisImagePath(haulisResponse?.path)
      }

      const homeColorResponse = await getHomeColorList();
      if (homeColorResponse.status === '1') {
        setColorList(homeColorResponse?.data);
        setColorImage(homeColorResponse?.path)
      }
      // setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      // setLoading(false);
    }
  };
  const topImage = async () => {
    const formData = new URLSearchParams();
    formData.append('section_type', 'top');
    let topImage = await getHomeSectionList(formData);
    // console.log('topImagetopImagetopImage',topImage);
    if (topImage.status === '1') {
      setTopBannerImage(topImage?.path);
      setTopData(topImage?.data)
    }
  }
  const middleImage = async () => {
    const formData = new URLSearchParams();
    formData.append('section_type', 'middle');
    let middleImage = await getHomeSectionList(formData);
    // console.log('middleImagemiddleImagemiddleImage',middleImage);
    if (middleImage.status === '1') {
      setMiddleBannerImage(middleImage?.path);
      setMiddleData(middleImage?.data)
    }
  }
  const bottomImage = async () => {
    const formData = new URLSearchParams();
    formData.append('section_type', 'offer');
    let bottomImage = await getHomeSectionList(formData);

    if (bottomImage.status === '1') {
      setOfferBannerImage(bottomImage?.path);
      setOfferData(bottomImage?.data)
    }
  }
  const offerImage = async () => {
    const formData = new URLSearchParams();
    formData.append('section_type', 'down');
    let bottomImage = await getHomeSectionList(formData);// console.log('bottomImagebottomImagebottomImage',bottomImage);
    if (bottomImage.status === '1') {
      setdownBannerImage(bottomImage?.path);
      setdownData(bottomImage?.data)
    }
  }




  useEffect(() => {
    if (hasMounted.current) return;
    fetchData();
    topImage();
    middleImage();
    offerImage();
    bottomImage();
    hasMounted.current = true;
  }, []);
  // console.log('bottomImage',downData);

  const inputElement = (
    <input
      type="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      placeholder="Email"
      required={true}
    />
  );
  const buttonElement = (
    <button type="submit">
      <FaArrowRight />
    </button>
  );

  // end contact Us element 
  const allGifts = "gifts";

  // ... git category
  const [giftCatPath, setGiftCatPath] = useState(null);
  const [giftCatData, setGiftCatData] = useState(null);
  useEffect(() => {
    const fetchGiftCategory = async () => {
      try {
        const response = await getGiftCategory();
        if (response.status === "1") {
          setGiftCatPath(response?.category_path)
          setGiftCatData(response?.data);
        }
      } catch (error) {
        //
      }
    }
    fetchGiftCategory();
  }, []);

  const cart = useSelector((state) => state.cart);
  const { token } = useSelector((state) => state.user);
  useEffect(() => {
    const getCartDataFromDB = async () => {
      try {
        const result = await getCart(token);

        if (result?.data?.length > 0) {
          const cartItems = result.data.map((item) => {
            const { id, product_name, product_slug, is_wishlist, price, compare_price, stock_quantity } = item.product;
            const image = `${result.path}${item.product.productimages[0].file_name}`;
            const newItem = { id, product_name, product_slug, is_wishlist, image, price, compare_price, count: item.quantity, p_variant_id: item.product_variant_id, stock_quantity };

            return newItem;
          });
          let updatedCart = [...cart, ...cartItems];

          let uniqueIds = {};
          updatedCart = updatedCart.filter(obj => {
            if (!uniqueIds[obj.id]) {
              uniqueIds[obj.id] = true;
              return true;
            }
            return false;
          });
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          dispatch(addToCart(updatedCart));
        }
      } catch (error) {
        // console.log('get cart api error', error);
      }
    };
    getCartDataFromDB();
  }, [])

  return (
    <>

      <CarouselCom sliderList={sliderList} carouselSpinner={carouselSpinner} />

      <Category categoryList={categoryList.data} imagePath={categoryList.path} />

      <NewArrival />

      <section className="home_img_full_middle">
        <Carousel>
          {topData?.map((topItem, index) => (
            <Carousel.Item key={index}>
              <Link to={`${allGifts}`}>
                <img src={`${topBannerImage}${topItem?.image}`} alt="#" /></Link>
            </Carousel.Item>
          ))}

        </Carousel>
      </section>

      <section className='four_cards_luxury'>
        <Row gutter={[24, 16]}>
          {
            giftCatData?.length > 0 && giftCatData.map((list, index) => (
              <Col key={index} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Link to={`${Routes.ProductCategory}/${list?.categories?.slug}`}>
                  <div className='luxury_crd'>
                    <img src={`${giftCatPath}/${list?.categories?.image}`} alt='asdasdasd' />
                    <div className='luxury_cardHeading'>
                      <h4>{list?.categories?.name}</h4>
                    </div>
                  </div>
                </Link>
              </Col>
            ))
          }
        </Row>
      </section>

      <BestSeller />

      <section className="home_img_full_last">

        <Link to="">
          <Carousel>
            {middleData?.map((item, index) => (
              <Carousel.Item key={index}>
                <img src={`${middleBannerImage}${item?.image}`} alt="#" />
                {/* <Carousel.Caption>
                <div className='giftly_heading_para'>
                  <h3>{bottomItem?.title}</h3>
                  <p>{bottomItem?.sub_title}</p>
                    <button>Shop Now</button>
                </div>
              </Carousel.Caption> */}
              </Carousel.Item>
            ))}
          </Carousel>
        </Link>
      </section>

      <OfferItem />

      <section className='shop_Our_Look'>

        {offerData?.map((OfferItem, index) => (
          <Link to="/offers">
            <img
              key={index} // Add a key for list items
              src={`${offerBannerImage}${OfferItem?.image}`}
              alt={`Banner ${index}`} // Provide meaningful alt text
            />
          </Link>
        ))}
      </section>


      <FinestProduct />

      <AboutUs aboutUsData={aboutUsData} />

      <section className="all_categ">
        {haulis?.map((haulisItem, index) => (
          <div className="sub_categ" key={index}>
            <Link to={haulisItem?.button_link || "/"}>
              <img src={`${haulisImagePath}${haulisItem?.image}`} alt="#" />
              <div className="sub_categ_text">
                <h3>{haulisItem?.title}</h3>
                <p>{haulisItem?.sub_title}</p>
                <button>Shop Now</button>
              </div>
            </Link>
          </div>
        ))}
      </section>
      {colorList?.length > 0 &&
        <section className='search_color'>
          <h3>Harmonious Hues</h3>
          <div className='color_container'>
            {colorList?.map((colorItem, index) => (
              <Link key={colorItem.id || index} to={`${Routes?.productByColor}/${colorItem?.slug}`}
                state={{ from: colorItem?.color, colorId: colorItem?.id }}>
                <div className='color_img_conat'>
                  <img src={`${colorImage}${colorItem?.image}`} alt='' />
                  <span>{colorItem?.color}</span>
                </div>
              </Link>
            ))}
          </div>
        </section>
      }

      <section className='journal_wrapper'>
        <div className="container-fluid">
          <div className='row'>
            <h3 className='store_headings'>The Kairaus Journal</h3>
            {blogs && blogs.data.map((blog) => (
              <div className="col-md-4 col-sm-6 col-12 plan_categ" key={blog.id}>
                <Link to={`/blog/${blog.slug}`}>
                  <img src={`${blogs.path}/${blog.image}`} alt={blog.title} />
                  <div className="plan-texts">
                    <h4>{blog.title}</h4>
                    {/* {truncateString(removeHtmlTags(blog.description), 60)} */}
                  </div>
                </Link>
              </div>
            ))}
          </div>
          {/* ./row */}
          <div className='blog_viewmore'>
            <Link to={Routes?.BlogCategory}>View More</Link>
          </div>
        </div>
      </section>

      {/* <section className='loyality_Look'>
        <div className='shop_Our_Item'>
          {downData?.map((downItem, index) => (
            <Link to={Routes?.Rewards}>
              <img
                key={index}
                src={`${downBannerImage}${downItem?.image}`}
                alt={`Banner ${index}`}
              />
            </Link>
          ))}
        </div>
      </section> */}

      <section className='ask_conatiner_cards'>
        <div className='final_container'>
          <Link to={Routes.ContactUs}>
            <div className="ask_query">
              <span>Have Questions?</span>
              <img src={asKSvg} alt="asKSvg" style={{ height: '194px' }} />
              <div className='ask_query_cont'>
                <Link to={Routes.ContactUs}>BOOK AN APPOINTMENT</Link>
              </div>
            </div>
          </Link>
          <Link onClick={showDrawer}>
            <div className="ask_query_second">
              <span>Bulk Orders</span>
              <img src={bulksvg} alt="asKSvg" style={{ height: '194px' }} />
              <div className='ask_query_cont'>
                <Link onClick={showDrawer}>INQUIRE ABOUT WHOLESALE</Link>
              </div>
            </div>
          </Link>
          <Link to={Routes.ContactUs}>
            <div className="ask_query">
              <span>Business Collaborations</span>
              <img src={collsvg} alt="asKSvg" />
              <div className='ask_query_cont'>
                <Link to={Routes.ContactUs}>GET IN TOUCH</Link>
              </div>
            </div>
          </Link>
          <Link to={Routes.AboutUs}>
            <div className="ask_query_second">
              <span>Know About Our Products</span>
              <img src={collsvg} alt="asKSvg" />
              <div className='ask_query_cont'>
                <Link to={Routes.AboutUs} className='footer_links'>About Us</Link>
              </div>
            </div>
          </Link>
        </div>
      </section>

      <section className="qty_info_main">
        <h2>Our Brand Pillars</h2>
        <div className='brand_pillar'>
          <div className="qty_info_sub">
            <img src={delivery} alt='' />
            <h6>Safe Home Delivery</h6>
            <span>Our delivery team treats your order like a VIP—Very Important Package—delivered right to your door!</span>
          </div>
          <div className="qty_info_sub">
            <img src={crdOnline} alt='' />
            <h6>Secure Online Payments</h6>
            <span>Pay with peace of mind; our security is rock-solid to keep your transactions safe and protected!</span>
          </div>
          <div className="qty_info_sub">
            <img src={shopWorld} alt='' />
            <h6>Shipping Worldwide</h6>
            <span>From our doorstep to yours, we’re like global delivery ninjas—swift and precise!</span>
          </div>
          <div className="qty_info_sub">
            <img src={experienced} alt='' />
            <h6>Quality Checked</h6>
            <span>We inspect each product like it’s a hidden gem—because, to us, it is!</span>
          </div>
        </div>
      </section>

      <section className='subcibe_Look'>
        <div className='shop_Our_Item'>
          <img src={SubscribeImg} alt="#" />
          <div className='shop_Our_card'>
            <p>Never miss out the latest updates</p>
            <h3>Subscribe Us</h3>
            <div className="contact_forms">
              <ContactUs input={inputElement} button={buttonElement} />
            </div>
          </div>
        </div>
      </section>
      {/* 
      {sessionStorage.getItem('videoPlay') != 'True' ?
        <div className="loading-video" style={{ transform: `translateY(${docLoading ? "0%" : "-100%"})` }}>
          <video autoPlay loop muted >
            <source src={loadingGif} type="video/mp4" />
          </video>
        </div>
        : ''
      } */}

      <BulkDrawer onClose={onClose} openDrawer={open} productId={'0'} />
    </>
  )
}

export default Home