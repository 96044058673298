import React, { useEffect, useRef, useState } from 'react';
import { Input, Form, Button, Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { authApi } from '../../services/auth/authApi';
import { toast } from 'react-toastify';
import Routes from '../../Routes/Routes';
import trashLottie from '../../assets/images/gmailVerify.json';
import Lottie from 'react-lottie';

const ForgetPass = () => {
    const emailName = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        emailName.current?.focus();
    }, []);

    const onFinish = async (values) => {
        try {
            const formData = new URLSearchParams();
            formData.append('email', values.email);
            const response = await authApi.forgotPassword(formData);

            if (response?.status === "1") {
                setIsModalOpen(true);
            } else {
                toast.error(response?.message || 'An error occurred.');
            }
        } catch (error) {
            console.error('Error fetching address data:', error);
            toast.error(error.message || 'An error occurred.');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
        navigate(Routes.SignIn);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: trashLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            <div className='BD_master_wrapper'>
                <div className='bd_model_forget'>
                    <h1>Forgot Password!</h1>
                    <span>No worries! Just enter your email, and we'll assist you.</span>
                    <Form name="control-hooks" layout="vertical" onFinish={onFinish} autoComplete="off">
                        <Form.Item
                            name="email"
                            label="Email Address"
                            rules={[
                                { required: true, message: 'Email is required' },
                                { type: 'email', message: 'Please enter a valid email' }
                            ]}
                        >
                            <Input
                                placeholder='Enter here...'
                                className="input_signin"
                                onKeyDown={handleKeyPress}
                                ref={emailName}
                            />
                        </Form.Item>

                        <div className='bd_model_button_forget'>
                            <Link to={Routes.SignIn}>
                            <span>Go Back</span>
                            </Link>
                            <Button type="primary" htmlType="submit" className='BG_mainButton'>
                                Submit
                            </Button>
                        </div>
                        <div className='Storepedia_forget'>
                            
                                New to Kairaus?
                           
                            <button key="submit" className='BG_mainButton2'>
                            <Link to={Routes.SignUp}>
                                Create an Account
                                 </Link>
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className='emailVerify_model'
                footer={
                    <div className='custom-modal-footer'>
                        <Button key="delete" onClick={handleOk} className='deleteButton'>
                            Got It!
                        </Button>
                    </div>
                }
            >
                <div className='delete_popUpDesign'>
                    <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                    />
                    <p>Check Your Email!</p>
                    <span>We've sent you a link to reset your password!</span>
                </div>
            </Modal>
        </>
    );
};

export default ForgetPass;
