import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Routes from '../../Routes/Routes';
import { clearBuy } from '../../reducers/buySlice';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../reducers/cartSlice';

const PaymentFailed = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch(); 

    useEffect(() => {
        const buy = JSON.parse(localStorage.getItem('buy')) || [];
          const cart = JSON.parse(localStorage.getItem('cart')) || [];
          buy.forEach(item => {
            const existingItemIndex = cart.findIndex(cartItem => cartItem.id === item.id);
            if (existingItemIndex !== -1) {
              cart[existingItemIndex].count += item.count;
            } else {
              cart.push(item);
            }
          });
          localStorage.setItem('cart', JSON.stringify(cart));
          dispatch(addToCart(cart));
          dispatch(clearBuy());
          localStorage.removeItem('buy');
      }, []);




    return (
        <>
            <div className='chcekout_thank_page'>
                <div className='checkout_failed'>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#d0021b" stroke="#d0021b" strokeWidth="3" strokeLinejoin="round" /><path d="M29.6567 18.3432L18.343 29.6569" stroke="#FFF" strokeWidth="3" clipRule="round" strokeLinejoin="round" /><path d="M18.3433 18.3432L29.657 29.6569" stroke="#FFF" strokeWidth="3" clipRule="round" strokeLinejoin="round" /></svg>
                    <h2> Your order cancelled </h2>
                    <span> Try again later </span> 
                </div>
                <div className='trackOrder'>
                    <button onClick={() => navigate("/")}>Continue Shopping</button>
                </div>
            </div>
            <div className='payment_footer'>
                <Link to={Routes?.Shipping} state={{ from: "#returnCancel" }}><span>Refund policy  </span></Link>
                <div className='profile_dashed'>|</div>
                <Link to={Routes?.Shipping} state={{ from: "#privacyPolicy" }}><span>Privacy policy </span></Link>
                <div className='profile_dashed'>|</div>
                <Link to={Routes?.Shipping} state={{ from: "#termsCond" }}><span>Terms & Conditions</span></Link>
            </div>
        </>
    )
}

export default PaymentFailed