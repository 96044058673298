import React, { useState, useEffect } from 'react';

const ScrollComponent = ({ totalProductCount, slug, gridCount }) => {
    const savedCount = parseInt(sessionStorage.getItem('productCount'), 10) || 0;
    const [showDiv, setShowDiv] = useState(false);
    const [count, setCount] = useState(gridCount === 'md' ? 3 : gridCount === 'sm' ? 2 : 4);
    const total = totalProductCount || savedCount;
    const [lastScrollY, setLastScrollY] = useState(0);

    const calculateVisibilityRatio = () => {
        const aspectRatio = window.innerHeight / window.innerWidth;
        return aspectRatio;
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        const screenVisibilityRatio = calculateVisibilityRatio();

        // Set showDiv based on whether count is greater than 0
        if (count > 0) {
            setShowDiv(currentScrollY > window.innerHeight * screenVisibilityRatio);
        } else {
            setShowDiv(false);
        }

        const deltaY = currentScrollY - lastScrollY;
        const thresholdHeight = window.innerHeight / 2.3;

        if (Math.abs(deltaY) >= thresholdHeight) {
            const step = gridCount === 'md' ? 3 : gridCount === 'sm' ? 2 : 4;
            setCount(prevCount => {
                if (deltaY > 0) {
                    return Math.min(prevCount + step, total);
                } else {
                    return Math.max(prevCount - step, 0);
                }
            });
            setLastScrollY(currentScrollY);
        }

        // Reset count when near the top of the page
        if (currentScrollY < 100 && count > 0) {
            setCount(0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY, count, gridCount, total]);

    // Reset count when gridCount changes
    useEffect(() => {
        setCount(gridCount === 'md' ? 3 : gridCount === 'sm' ? 2 : 4);
    }, [gridCount]);

    // Ensure count doesn't exceed total products
    useEffect(() => {
        if (count > total) {
            setCount(total);
        }
    }, [total]);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            {count > 0 && showDiv && (
                <div className='button_loading'>
                    <button onClick={scrollToTop}>
                        <svg width="17" height="17" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 6V42" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 18L24 6L36 18" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> {slug} : {count}/{total}
                    </button>
                </div>
            )}
        </div>
    );
};

export default ScrollComponent;
