// src/utils/cartUtils.js
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCustomerSupport } from "../../services/assurance";

const CalculateTotals = () => {
  const buy = useSelector(state => state.buy);
  const buyData = buy ? buy : [];
  const cartIds = useSelector((state) => state.cartId);
  const cart = useSelector((state) => state.cart);
  const couponSlice = useSelector((state) => state.coupon);
  const rewardSlice = useSelector((state) => state.reward);
  // console.log('buyData',buyData);
  const shippingFees = useSelector((state) => state.shippingFees);
  const [supportData, setSupportData] = useState()
  const excRate = useSelector(state => state.excrate);

  const handleSupport = async () => {
    try {
        let result = await getCustomerSupport();
        setSupportData(result?.data?.max_shipping_amount);
    }
    catch (e) {
        console.error('error', e);
    }
}

useEffect(()=>{
    handleSupport()
},[])


  let firstOrderCouponDetail = localStorage.getItem('FirstOrderCouponDetails');
  firstOrderCouponDetail = JSON.parse(firstOrderCouponDetail);

  const localCartIds = JSON.parse(localStorage.getItem('localCartIds'));
  // console.log('buyData',buyData);

  const carts = localCartIds?.length > 0 ? localCartIds : cartIds;
  const filteredCartData = buyData !== null && buyData.length > 0 ? buyData : cart.filter((product) => carts.includes(product.id));
  // console


  const cartLength = filteredCartData.length;  // console.log('filteredCartData',filteredCartData);
  // console.log('filteredCartData',filteredCartData);

  



  const total = filteredCartData.reduce(
    (accumulator, { compare_price = 0,usd_price=0,usd_compare_price=0, price = 0, count = 0, newPrice=0 }) => {
      const effectivePrice = newPrice || price;
      accumulator.totalComparePrice += excRate?.currencyCode === "INR" ?(compare_price > 0 ? compare_price : effectivePrice) * count:(usd_compare_price > 0 ? usd_compare_price : usd_price) * count;
      accumulator.totalPrice += excRate?.currencyCode === "INR" ?effectivePrice * count:usd_price * count;
      accumulator.totalCount += count;

  return accumulator;
    },
    { totalPrice: 0, totalComparePrice: 0, totalCount: 0 }
  );
  
  const bagDiscount =total.totalComparePrice-total.totalPrice;
  console.log(total,"effectivePriceeffectivePrice")
  
  // let disAmount =0;
  // if(buyData.length <= 0){
  //   if (couponSlice) {
  //     if (couponSlice.coupon_type === 'percent') {
  //       const amountOfPercent = couponSlice.type_val / 100;
  //       disAmount = total.totalPrice * amountOfPercent;
  //     } else if (couponSlice.coupon_type === 'normal') {
  //       disAmount = couponSlice.type_val;
  //     }
  //   }
  // }
  let totalPrice = 0;
  let firstOrderCouponDiscount=0;

if (firstOrderCouponDetail?.order_count === 0) { // Case of first order
     firstOrderCouponDiscount = total.totalPrice * (firstOrderCouponDetail?.data?.type_val / 100);
    totalPrice = total.totalPrice - firstOrderCouponDiscount;
    // console.log('totalPricetotalPrice',totalPrice);
    // console.log('firstOrderCouponDiscount==50=>>', grandTotal);
} else {
    totalPrice = total.totalPrice;
}

  let disAmount = 0;
  if (couponSlice) {
    if (couponSlice.coupon_type === 'percent') {
      const amountOfPercent = couponSlice.type_val / 100;
      disAmount = totalPrice * amountOfPercent;
    } else if (couponSlice.coupon_type === 'normal') {
      disAmount = couponSlice.type_val;
    }
  }
  

  const shippingCharge = filteredCartData.reduce((accumulator, product) => {
    if (product.shipping_amount_type == '0') {
      accumulator += product.shipping_charge;
    } else if (product.shipping_amount_type == '1') {
      accumulator += (product.shipping_charge) * product.count;
    } else if (product.shipping_amount_type == '2') {
      accumulator += (product.price * product.shipping_charge / 100) * product.count;
    }
    return accumulator;
  }, 0);

  // console.log(shippingCharge,"shippingChargeshippingCharge")

  const rewardAmnt = total.totalPrice > 0 ? rewardSlice || 0 : null;
  let grandTotal = totalPrice- disAmount - (rewardAmnt > 0 ? rewardAmnt : 0);

  // let firstOrderCouponDiscount = 0;
  // if (firstOrderCouponDetail?.order_count == 0) { // Case of first order
  //   if (grandTotal > 0) {
  //     firstOrderCouponDiscount = grandTotal * (firstOrderCouponDetail?.data?.type_val / 100);
  //     grandTotal = grandTotal - firstOrderCouponDiscount;
  //     // console.log('firstOrderCouponDiscount==50=>>', grandTotal);
  //   }
  //   else {
  //     return grandTotal;
  //   }
  // }
  // else {
  //   grandTotal = grandTotal;
  // }
  // console.log('grandTotal=59==>>>',grandTotal);

  const newShippingCharges = grandTotal > supportData ? 0 : shippingCharge
  const addShippingCharges = grandTotal + newShippingCharges

  const newGrandTotal = newShippingCharges >0 ? addShippingCharges : grandTotal;
  //  const savingAmnt= bagDiscount+disAmount+(rewardAmnt > 0 ? rewardAmnt : 0);

  let savingAmnt = bagDiscount + disAmount + (rewardAmnt > 0 ? rewardAmnt : 0)
  savingAmnt += firstOrderCouponDiscount > 0 ? firstOrderCouponDiscount : 0;

  // const savingAmnt = total?.totalComparePrice - grandTotal;
  return {
    total,
    disAmount,
    grandTotal,
    cartLength,
    filteredCartData,
    bagDiscount,
    rewardAmnt,
    savingAmnt,
    newGrandTotal,
    shippingCharge,
    rewardSlice,
    newShippingCharges,
    firstOrderCouponDiscount
  };
};

export default CalculateTotals;
