import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Form,
  Row,
  Col,
  Checkbox,
} from "antd";
import { toast } from "react-toastify";
import { signUpData } from "../../services/auth/singUp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToUser } from "../../reducers/userSlice";
import Routes from "../../Routes/Routes";
import { Link } from "react-router-dom";
import { addCart, getCart } from "../../services/cart/addCart";
import { addToCart } from "../../reducers/cartSlice";
import { applyCoupon } from "../../reducers/couponSlice";

function Signup() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const user = useSelector((state) => state.user);
  let cart = useSelector((state) => state.cart);


  useEffect(() => {
    // Check if the user is already logged in

    if (user?.id) {
      navigate(Routes.Home, { replace: true });
    }
  }, [navigate, user]);

  // const countryChange = useCallback(async (value) => {
  //   setCountryId(value);
  //   setStateId(null);
  //   setCityId(null)
  //   const stateList = await fetchState(value);
  //   setState(stateList);
  // }, [form]);

  const [errorOccurred, setErrorOccurred] = useState(false);

  // Fetch data from DB
  const getCartDataFromDB = async (token) => {
    try {
      const result = await getCart(token);

      if (result?.data?.length > 0) {
        const cartItems = result.data.map((item) => {
          const { id, product_name, product_slug, is_wishlist, price } = item.product;
          const image = `${result.path}${item.product.productimages[0].file_name}`;
          const newItem = { id, product_name, product_slug, is_wishlist, image, price, count: item.quantity, p_variant_id: 0 };

          return newItem;
        });
        let updatedCart = [...cart, ...cartItems];

        // To prevent by duplicate product in cart
        let uniqueIds = {};
        updatedCart = updatedCart.filter(obj => {
          if (!uniqueIds[obj.id]) {
            uniqueIds[obj.id] = true;
            return true;
          }
          return false;
        });
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        dispatch(addToCart(updatedCart));
      }
    } catch (error) {
      // console.log('get cart api error', error);
    }
  };
  const [issubmit, setIssubmit]=useState(false);
  // console.log(issubmit,"issubmitissubmit")
  

  const handleSubmit = async (values) => {
    localStorage.removeItem("FirstOrderCouponDetails");
    if(values){
      setIssubmit(true);
    }

    const { fname, lastname, email, password, mobile_number } = values;

    try {
      const formData = new URLSearchParams();
      formData.append('firstname', fname);
      formData.append('lastname', lastname);
      formData.append('email', email);
      formData.append('mobile_number', mobile_number);
      formData.append('password', password);
      formData.append('referrer', '');
      // formData.append('country_id', country_id);
      // formData.append('state_id', stateId);
      // formData.append('city_id', cityId);
      // formData.append('pincode', pincode);
      // formData.append('birthday', dob);

      const responseData = await signUpData(formData);
      // console.log('responseData', responseData);

      if (responseData.status === '1') {
        const couponnull = {};
        dispatch(applyCoupon(couponnull));
        localStorage.setItem('coupon', JSON.stringify(couponnull));
        toast.success(responseData.message);
        const { id, email, userfullname } = responseData.data;
        const userInfo = { id, email, userfullname, token: responseData.token };
        localStorage.setItem('user', JSON.stringify(userInfo));

        // Add cart data to DB
        if (cart.length > 0) {
          const products = cart?.map(product => ({
            product_id: product.id,
            product_variant_id: product.p_variant_id,
            quantity: product.count,
            amount: product.price,
          }));
          await addCart(products, responseData.token);
        }

        getCartDataFromDB(userInfo.token);
        navigate(Routes?.ThankYouSign);
        dispatch(addToUser(userInfo));
        setIssubmit(false);
      }
    } catch (error) {
      setIssubmit(false);
      console.error('API Request Error:', error);
      if (!errorOccurred) {
        toast.error(error?.message);
        setErrorOccurred(true);
      }

    }
  };
  const handleFormValuesChange = () => {
    setErrorOccurred(false);
  };

  const firstName = useRef(null);
  useEffect(() => {
    firstName.current.focus();
  }, [])

  // const countryLabel = () => (<span>Country <span style={{ color: '#FF7F7F' }}>*</span></span>)
  // const stateLabel = () => (<span>State <span style={{ color: '#FF7F7F' }}>*</span></span>)
  // const cityLabel = () => (<span>City <span style={{ color: '#FF7F7F' }}>*</span></span>)

  const handleKeyPressForMobile = (e) => {
    const key = e.key;
    const value = e.target.value;
    const cursorPosition = e.target.selectionStart;

    if (cursorPosition === 0 && key === '0') {
      e.preventDefault();
      return;
    }
    if (!(key >= '0' && key <= '9') && key !== 'Backspace' && key !== 'Tab' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      // If the pressed key is not a number (0-9), the backspace key, or the tab key
      e.preventDefault(); // Prevent typing
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };


  const handleKeyPressName = (e) => {
    const key = e.key;
    if (key >="0" && key <= "9" || !(key.match(/[a-zA-Z\s]/))) { 
      e.preventDefault(); 
    }
  };

  return (
    <>
      <div className="BD_signup_wrapper">
        <div className="bd_model_left">
          <h1>Sign Up!</h1>
          <h4>
            Sign up and enjoy exclusive deals, offers, invites and rewards.
          </h4>

          <Form
            name="control-hooks"
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
            onValuesChange={handleFormValuesChange}
          >
            <Row gutter={18}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="fname"
                  label={<>First Name</>}
                  rules={[
                    {
                      required: true,
                      message: 'First name is required'
                    },

                  ]}
                >
                  <Input className="input_signin" ref={firstName}
                    onKeyDown={handleKeyPressName} onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      form?.setFieldsValue({ fname: trimmedValue });

                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="lastname"
                  label={<>Last Name</>}
                  rules={[{
                    required: true,
                    message: 'Last name is required'
                  },
                    // {
                    //   pattern: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                    //   message: 'Last name cannot contain spaces, special characters or numbers'
                    // }
                  ]}
                >
                  <Input className="input_signin" onKeyDown={handleKeyPressName} 
                      onChange={(e) => {
                        const trimmedValue = e.target.value.trimStart();
                        form?.setFieldsValue({ lastname: trimmedValue }); 
                    }}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item
                  label={countryLabel()}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    name="country_id"
                    // placeholder="Select Country"
                    optionFilterProp="children"
                    onChange={(e) => {
                      countryChange(e);
                      // stateChange(null)

                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={country_id || undefined}
                  >
                    {countries &&
                      countries?.sort((a, b) => a.country_name.localeCompare(b.country_name))?.map((country, index) => (
                        <Select.Option key={index} value={country.id}>
                          {country.country_name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col> */}

              {/* <Col span={8}>
                <Form.Item
                  label={stateLabel()}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    name="state_id"
                    // placeholder="Select State"
                    optionFilterProp="children"
                    onChange={stateChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={stateId || undefined}
                  >
                    {stateAllData &&
                      stateAllData?.sort((a, b) => a.state_name.localeCompare(b.state_name))?.map((state, index) => (
                        <Select.Option key={index} value={state.id}>
                          {state.state_name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col> */}

              {/* <Col span={8}>
                <Form.Item
                  label={cityLabel()}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    name="city_id"
                    // placeholder="Select City"
                    optionFilterProp="children"
                    onChange={cityChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={cityId || undefined}
                  >
                    {cityAllData &&
                      cityAllData?.sort((a, b) => a.city_name.localeCompare(b.city_name))?.map((city, index) => (
                        <Select.Option key={index} value={city.id}>
                          {city.city_name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col> */}
              {/* <Col span={12} className="pincodeMargin">
                <Form.Item
                  name="pincode"
                  label="Pincode"
                  rules={[
                    {
                      required: true,
                      message: 'Pin code is required'
                    },
                    {
                      pattern: /^[0-9]{6}$/,
                      message: 'Please enter a valid 6-digit pincode',
                    },]}
                >
                  <Input type="text" className="input_signin" maxLength={6} />
                </Form.Item>
              </Col> */}

              {/* <Col span={12} className="pincodeMargin">
                <Form.Item
                  name="dob"
                  label="Date of Birth"
                  rules={[
                    { required: true, message:'Date of birth is required' },
                   
                  ]}
                >
                 
                  <DatePicker className="input_signin" style={{width:'100%'}} format="YYYY-MM-DD" placeholder="DD/MM/YYYY" disabledDate={(current) => current && current > dayjs()} />
                </Form.Item>
              </Col> */}

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="email"
                  label={<>Email Address</>}
                  rules={[{ required: true, message: 'Email is required' }, { type: "email", message: "Please enter a valid email" },]}
                >
                  <Input type="email" className="input_signin" onKeyDown={handleKeyPress} autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="mobile_number"
                  label={<>Phone Number</>}
                  rules={[
                    { required: true, message: 'Mobile number is required' },
                    {
                      pattern: /^[0-9]{10}$/, // Validate for a 10-digit number
                      message: 'Please enter a valid mobile number',
                    },
                  ]}>
                  <Input type="text" className="input_signin" onKeyDown={handleKeyPressForMobile} maxLength={10} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="password"
                  label={<>Create Password</>}
                  rules={[{ required: true, message: "Password is required" }, {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-+=])[A-Za-z\d!@#$%^&*()-+=]{8,20}$/,
                    message: <>
                      <ul>
                        <li>Password must be between 8 to 20 characters</li>
                        <li>Password must contain at least one UpperCase letter (A-Z)</li>
                        <li>Password must contain at least one LowerCase letter (a-z)</li>
                        <li>Password must contain at least one number (0-9)</li>
                        <li>Password must contain at least one special characters</li>
                      </ul>
                    </>
                  },
                  ]}
                >
                  <Input.Password className="input_signin"
                    onKeyDown={handleKeyPress} maxLength={20} autoComplete="new-password" />
                </Form.Item>
              </Col>
              {/* <Col span={24}>
                <Form.Item
                  name="confirmpassword"
                  label={<>Confirm Password</>}
                  rules={[{ required: true, message: "Password is required" }, {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-+=])[A-Za-z\d!@#$%^&*()-+=]{8,20}$/,
                  },
                  ]}
                >
                  <Input.Password className="input_signin"
                    onKeyDown={handleKeyPress} maxLength={20} />
                </Form.Item>
              </Col> */}
            </Row>

            <div className="checkbox_sigup">
              <Checkbox>
                {" "}
                Yes, email me my member rewards, special invites, trend alerts &
                more,
              </Checkbox>
            </div>
            {/* <div className="chekbox_text_sign">
              <p>
                By clicking ‘Become a member’, I agree to the Storepedia
                Membership <Link to="/terms">Terms and conditions</Link>
              </p>
              <span>
                {" "}
                To give you the full membership experience, we will process your
                personal data in accordance with the Storepedia’s Privacy
                Notice.
              </span>
            </div> */}
            <div className="bd_model_button">
              
              <button key="submit" disabled={issubmit}>Submit</button>
            </div>
            <div className="form_footer_content">
              <p>
                Already have an account? <Link to={Routes.SignIn}>Log in</Link>
              </p>
            </div>
          </Form>
        </div >
      </div >
    </>
  );
}

export default Signup;
