import React, { useEffect, useState } from 'react';
import { TruckOutlined } from '@ant-design/icons';
import "../../assets/css/style/checkout.scss"
import { getCustomerSupport } from '../../services/assurance';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import ProductPrice from '../ProductPrice';
import EstimatedDelivery from '../EstimatedDelivery';


const CheckoutCartProduct = ({ product }) => {
  const comp = useSelector(state => state.shippingFees);
  const [supportData, setSupportData] = useState({});
  const getLocalData = localStorage.getItem("bufferdays");
  // const excRate = useSelector(state => state.excrate);

  const handleSupport = async () => {
    try {
      let result = await getCustomerSupport();
      setSupportData(result?.data);
    }
    catch (e) {
      console.error('error', e);
    }
  }

  useEffect(() => {
    handleSupport()
  }, [])

  

  

  return (
    <div className={`checkout_bag`}>
      <div className='cart-products'>
        <div className='left_cart'>
          {product.image ? (
            <Link to={`${Routes?.ProductDetail}/${product.product_slug}`} state={{ ProductId: product?.id }}>
              <img src={product.image} alt={product.product_name} />
            </Link>

          ) : (
            'Image Not Found'
          )}
        </div>
        <div className='right_cart'>
          <div className='price_delete align-items-start'>
            <Link to={`${Routes?.ProductDetail}/${product.product_slug}`} state={{ ProductId: product?.id }}>
              <h2>{product.product_name}</h2>
            </Link>
            
            <div className='cartProduct_price'>
              <ProductPrice product={product} />
            </div>

          </div>
          <span>QTY : {product.count}</span>

          <EstimatedDelivery supportData={supportData} />

        </div>

      </div>
    </div>
  );
};

export default CheckoutCartProduct;
