import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import ProceedSection from './ProceedSection';
import { applyCoupon } from '../../reducers/couponSlice';
import { getApplyCouponData } from '../../services/coupon/applyCoupon';
import { toast } from 'react-toastify';
import { STRINGS } from '../../constants/Constant';
import Routes from '../../Routes/Routes';
import { useNavigate } from 'react-router-dom';
import { updateCartItem } from '../../reducers/cartSlice';

const DrawerCuppon = (props) => {
  // console.log('props',props);
  const dispatch = useDispatch();
  const [coupons, setCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState('');
  const [isProductCouponApplied, setIsProductCouponApplied] = useState(false);
  const { drawerClose, openCupponDrawer, grandTotal, initialCoupons, disAmount } = props;
  // console.log('initialCoupons',initialCoupons);
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const handleCouponCode = (c_code) => {
    setCouponCode(c_code)
  }
  // useEffect(() => {
  //   setCoupons(initialCoupons);
  // }, [initialCoupons]);
  const productCouponId = JSON.parse(localStorage.getItem('productCoupon')) || [];

  useEffect(() => {
    // setCoupons((prevCoupons) => prevCoupons.map((prevCoupon) => productCouponId.some((productCoupon) => productCoupon.couponId === prevCoupon.id) ? { ...prevCoupon, applied: true } : prevCoupon))
    setCoupons(initialCoupons.map((coupon) =>
      productCouponId.some((productCoupon) => productCoupon.couponId === coupon.id)
        ? { ...coupon, applied: true }
        : coupon
    ));
    setIsProductCouponApplied(false)
  }, [initialCoupons]);

  const handleApplyCoupon = async (couponId, coupon) => {
    if (grandTotal < 100) {
      toast.error(`Minimum cart amount to apply this coupon is ₹100`, { toastId: "1" });
      return;
    }

    // if (coupon.upto_amount !== "" && grandTotal > coupon.upto_amount) {
    //   toast.error(`Miximum order amount to apply this coupon is ₹${coupon.upto_amount}`, { toastId: "1" });
    //   return;
    // }
    // alert('jhkfdjsjk');

    const FirstOrderCouponDetails = JSON.parse(localStorage.getItem('FirstOrderCouponDetails')) || {};
    // alert(productCouponId.length);

    if (FirstOrderCouponDetails.status === "1" && productCouponId.length > 0) {
      toast.error("Only two coupons can be applied at a time", { toastId: "1" });
      return;
    }

    try {
      const formData = new URLSearchParams();
      formData.append('coupon_code', coupon.coupon_code);
      const response = await getApplyCouponData(formData, user.token);
      // console.log('getApplyCouponData',response);
      // console.log('coupon.coupon_code',coupon.coupon_code);
      if (response.status === '1') {
        const { coupon_type, type_val, id, coupon_code } = response.data;
        const couponDis = { coupon_type, type_val, id, coupon_code };
        // check coupon ids
        if (FirstOrderCouponDetails.status === "1") {
          productCouponId.forEach(item => {
            const storeUpdatePrice = {
              productId: item.productId,
              newPrice: item.oldPrice,
              discountApply: true
            };
            dispatch(updateCartItem(storeUpdatePrice));
          });
          localStorage.removeItem('productCoupon');
        }
        // Check if productCouponId is an array and its length is greater than or equal to 2
        if (Array.isArray(productCouponId) && productCouponId.length >= 2) {
          // Construct the object for updating the cart item
          const storeUpdatePrice = {
            productId: productCouponId[1].productId, // Assuming you want to access the second element (index 1)
            newPrice: productCouponId[1].oldPrice, // Assuming you want to access the second element (index 1)
            discountApply: true
          };

          // Dispatch the action to update the cart item
          dispatch(updateCartItem(storeUpdatePrice));

          // Remove the second item from local storage
          productCouponId.splice(1, 1);
          localStorage.setItem('productCoupon', JSON.stringify(productCouponId));
        }

        dispatch(applyCoupon(couponDis));
        localStorage.setItem('coupon', JSON.stringify(couponDis));
        //
        // setCoupons((prevCoupons) => prevCoupons.map((prevCoupon) => prevCoupon.id === couponId ? { ...prevCoupon, applied: true } : prevCoupon)
        // );

        setCoupons((prevCoupons) => {
          // Mark all non-product coupons as not applied
          const updatedCoupons = prevCoupons.map((prevCoupon) =>
            productCouponId.some((productCoupon) => productCoupon.couponId === prevCoupon.id)
              ? { ...prevCoupon, applied: true } // Keep product coupons always applied
              : { ...prevCoupon, applied: false } // Mark non-product coupons as not applied
          );

          // Apply the new coupon
          return updatedCoupons.map((prevCoupon) =>
            prevCoupon.id === couponId ? { ...prevCoupon, applied: true } : prevCoupon
          );
        });
        setIsProductCouponApplied(true)
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        toast.error("Please try again later.");
        console.error('Error fetching coupon data:', error?.response?.status);

      }
    }
  };


  const handleRemoveCoupon = (couponId) => {
    setCoupons((prevCoupons) =>
      prevCoupons.map((coupon) =>
        coupon.id === couponId
          ? { ...coupon, applied: false, removed: true, showRemoveConfirmation: false }
          : coupon
      )
    );
    const couponnull = {};
    dispatch(applyCoupon(couponnull));
    localStorage.setItem('coupon', JSON.stringify(couponnull));
  };

  const toggleRemoveConfirmation = (couponId) => {
    setCoupons((prevCoupons) =>
      prevCoupons.map((coupon) =>
        coupon.id === couponId
          ? { ...coupon, showRemoveConfirmation: !coupon.showRemoveConfirmation }
          : coupon
      )
    );
  }

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleApplyButtonClick = async () => {
    const couponCodes = JSON.parse(sessionStorage.getItem('couponCodes')) || [];
    if (!couponCodes.includes(couponCode)) {
      toast.error('Invalid coupon code');
      return;
    }
    try {
      const formData = new URLSearchParams();
      formData.append('coupon_code', couponCode);
      const response = await getApplyCouponData(formData, user.token);
      // console.log('setCouponCode',response);
      if (response.status === '1') {
        const to_date = response && response.data && response.data.to_date;
        if (new Date(to_date) >= new Date()) {
          const responsecouponId = response && response.data && response.data.id;
          setCouponCode('');
          setCoupons((prevCoupons) =>
            prevCoupons.map((prevCoupon) =>
              prevCoupon.id === responsecouponId ? { ...prevCoupon, applied: true } : { ...prevCoupon, applied: false }));
          //
          const { coupon_type, type_val, id, coupon_code } = response.data;
          const couponDis = { coupon_type, type_val, id, coupon_code };
          dispatch(applyCoupon(couponDis));
          localStorage.setItem('coupon', JSON.stringify(couponDis));
        } else {
          toast.error(STRINGS.COUPON_EXPIRED);
        }
      } else {
        // Show error in toast for invalid coupon
        toast.error(STRINGS.COUPON_NOT_VALID);
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      if (couponCode === '') {
        toast.error("Please enter a coupon code")
      } else {
        toast.error(STRINGS.COUPON_NOT_VALID);
      }
    }
  };

  return (
    <Drawer title={`Your Cart (${cart.length})`} onClose={drawerClose} open={openCupponDrawer} width={600}>
      <div className="drawr_cupns_main">
        <div className="cupns_input">
          <input type="text" placeholder='Enter Coupon Code' value={couponCode} onChange={handleCouponCodeChange} />
          <button onClick={handleApplyButtonClick}>Apply</button>
        </div>
        <div className="cupns_list_all">
          {coupons?.map((coupon) => (
            <CouponListSubItem
              key={coupon.id}
              coupon={coupon}
              onApply={handleApplyCoupon}
              onRemove={toggleRemoveConfirmation}
              handleCouponCode={handleCouponCode}
              onClose={drawerClose}
              productCouponId={productCouponId}
            />
          ))}
        </div>
      </div>
      <ProceedSection grandTotal={grandTotal} onClose={drawerClose} />

      {isProductCouponApplied && coupons?.some((coupon) => coupon.applied) && (
        <div className="show_offer_details">
          <h2>You Saved Rs. {disAmount.toFixed(2)}</h2>
          <p>Congratulations on your savings!</p>
          <button className='proceed_btn' onClick={drawerClose}>View Cart</button>
        </div>
      )}
      {coupons?.some((coupon) => coupon.showRemoveConfirmation) && (
        <div className="show_offer_details">
          <h2>Remove Coupon</h2>
          <p>Are you sure you don’t want to use this coupon?</p>
          <div className="cancel_btns">
            <button className='remove_btns_b' onClick={() => handleRemoveCoupon(coupons.find((coupon) => coupon.showRemoveConfirmation)?.id)}>Remove</button>
            <button className='remove_btns_b' onClick={drawerClose}>Cancel</button>
          </div>
        </div>
      )}
    </Drawer>
  );
};

const CouponListSubItem = ({ coupon, onApply, onRemove, handleCouponCode, onClose, productCouponId }) => {
  // console.log('coupon', coupon);
  const navigate = useNavigate();

  const handleViewMoreClick = (from) => {
    // Use curly braces to define an object with the key "coupons"
    navigate(Routes.AccountProfile, { state: { from } });
    // drawerClose();
    onClose();
  };
  // const currentDate = new Date();
  // console.log('currentDatecurrentDatecurrentDate',currentDate);
  const currentDate = new Date();
  const couponExpirationDate = new Date(coupon.to_date);
  currentDate.setHours(0, 0, 0, 0); // Resetting hours, minutes, seconds, and milliseconds
  couponExpirationDate.setHours(0, 0, 0, 0); // Resetting hours, minutes, seconds, and milliseconds

  return (
    <>
      <div className='cupns_list_detail'>
        <div className='per_off_detail'>
          <p>{coupon.coupon_desc}</p>
          {coupon.applied && <span><IoCheckmarkCircle style={{ fontSize: "22px" }} />Applied </span>}
        </div>
        <div className="coupon">
          <div className="center">
            <div className='copoons_cenetr'>
              <h2 onClick={() => handleCouponCode(coupon.coupon_code)}> {coupon.coupon_code}</h2>
              {
                coupon.applied ? (
                  productCouponId.some((productCoupon) => productCoupon.couponId === coupon.id) ? (
                    ""
                  ) : (
                    <button onClick={() => onRemove(coupon.id)}>Remove</button>
                  )
                ) : (
                  couponExpirationDate.getTime() >= currentDate.getTime() ? (
                    <button onClick={() => onApply(coupon.id, coupon)}>Apply</button>
                  ) : (
                    <div className='disablepayBtn'> <button disabled>Coupon Expired</button></div>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrawerCuppon;
