// services/contactUs.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};

export const submitContactForm = async (data) => {
  try {
    const response = await api.post('/contact-us', data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const addContactUsForm = async (data) => {
  try {
    const response = await api.post('/add-query', data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getContactUsFaqList= async (data,token) => {
  try {
    const response = await api.post('/get-faq-list', data, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const addContact= async (data,token) => {
  try {
    const response = await api.post('/add-contact-us', data, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getSearchContactUs= async (data,token) => {
  try {
    const response = await api.post('/get-search-data', data, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getContactQueryList = async () =>{
  try{
    const response = await api.get('/query-list');
    if(response.status === 200){
      return response.data;
    }else{
      throw new Error(`API Error: ${response.statusText}`)
    }
  }catch(error){
    return handleApiError(error);
  }
}
