import React, { useState, useEffect } from 'react';
import img4 from '../../assets/images/drinkwarecup3.png'
import { LiaGreaterThanSolid } from "react-icons/lia";
import { Collapse, Skeleton } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RightOutlined, PlusOutlined, MinusOutlined, LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { categoryApi } from '../../services/category/categoryApi';
import Routes from "../../Routes/Routes";
import Wishlist from '../../function/Wishlist';
import ProductSliderImg from '../../assets/images/product-silderImg/bottle.png';
import ProductSliderImg1 from '../../assets/images/product-silderImg/bowl.png';
import ProductSliderImg2 from '../../assets/images/product-silderImg/cup3.png';
import ProductSliderImg3 from '../../assets/images/product-silderImg/bowl2.png';
import ProductSliderImg4 from '../../assets/images/product-silderImg/mug3.png';
import ProductSliderImg5 from '../../assets/images/product-silderImg/sculpture4.png';
import ProductSliderImg6 from '../../assets/images/product-silderImg/mug11.png';
import ProductSliderImg7 from '../../assets/images/product-silderImg/soap-dispensor.png';
import ProductSliderImg8 from '../../assets/images/product-silderImg/pot3.png';
import { CategoryList } from '../../services/filter/categories';

import { Carousel } from 'react-bootstrap';

import AddToCart from '../../function/AddToCart';
import NoDataimg from '../../assets/images/noData.png'
import { addToSlug } from '../../reducers/slugSlice';
import ProductPrice from '../../components/ProductPrice';

const DiningPlates = () => {
    const [activeSpan, setActiveSpan] = useState(null);
    const excRate = useSelector(state => state.excrate);
    // const [selectedColor, setSelectedColor] = useState(null);
    const { slug } = useParams();
    const user = useSelector((state) => state.user);
    const [list, setList] = useState([]);
    const [path, setPath] = useState('');
    const [menuDetails, setMenuDetails] = useState({});
    const [productList, setProductList] = useState([]);
    const [productPath, setProductPath] = useState(null);
    const [sliderPath, setSliderPath] = useState(null);//setSliderPath
    const [categoryList, setCategoryList] = useState([]);
    const [checkedIds, setCheckedIds] = useState([]);
    const [setSelectedChildName] = useState([]);
    const dispatch = useDispatch();
    // console.log('slugslugslug',slug);
    const navigate = useNavigate();
    const handleItemClick = (slug, from, menuSlug, route, categorySlug) => {
        // console.log(menuSlug,"menuSlug")
        navigate(`${Routes.ProductCategory}/${slug}`, { state: { from, menuSlug, route, categorySlug } });
    }
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        const menuSegmentsString = sessionStorage.getItem('menuSlug');
        if (!menuSegmentsString) return; // If menuSegmentsString is null or undefined, return early

        const menuSegments = JSON.parse(menuSegmentsString); // Parse the string back into an array
        const validSegments = new Set(menuSegments); // Convert menuSegments to a Set  
        if (!validSegments.has(slug)) {
            // console.log('slugslugslugslugslugslug:',slug);
            // const slugInfo = {slug};
            dispatch(addToSlug({ slug }));
            navigate(`/${slug}`);
        }
    }, [slug, navigate]);

    const onCheckbox = (id, childName) => {
        setCheckedIds((prevIds) => {
            const isCurrentlyChecked = prevIds.includes(id);

            if (isCurrentlyChecked) {
                // Remove the slug if it's already checked
                return prevIds.filter((checkedId) => checkedId !== id);
            } else {
                // Add the slug if it's not checked
                return [...prevIds, id];
            }
        });
        setSelectedChildName((prevNames) => {
            const namesArray = Array.isArray(prevNames) ? prevNames : [];
            const isNameSelected = namesArray.includes(childName);
            if (!isNameSelected) {
                return [...namesArray, childName];
            } else {
                return namesArray.filter((name) => name !== childName);
            }
        });
    };

    const fetchCategoryList = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('slug', slug);

            const response = await CategoryList(formData);
            // console.log('CategoryList==========vivek', response);
            if (response.status === '1') {
                setCategoryList(response.data)
            } else {
                // console.log('Response', 'Record Not Found !');
            }
        } catch (error) {
            console.error('Error fetching product list :', error);
        }
    }
    const [isProLoading, setIsProLoading] = useState(false)
    const menuList = async () => {
        const formData = new URLSearchParams();
        formData.append('user_id', user?.id ? user?.id : 0);
        try {
            setIsProLoading(true)
            const response = await categoryApi.menuDataList(slug, formData);
            // console.log('response menuDataList', response);
            if (response.status === '1') {
                setIsProLoading(false)
                setList(response?.category)
                setPath(response?.category_path)
                setMenuDetails(response?.menus_detail?.menus)
                setProductList(response?.data)
                setProductPath(response?.product_path)
                setSliderPath(response?.page_slider)
            } else {
                // console.log('Response', 'Record Not Found!');
            }
        } catch (error) {
            setIsProLoading(false)
            console.error('Error fetching product list:', error);
        }
    }

    useEffect(() => {
        menuList()
        fetchCategoryList()
    }, [slug])

    const handleSpanClick = (index) => {
        setActiveSpan(index);
    };

    const onChange = (key) => {
        // console.log(key);
    };

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
                <div style={{ color: '#000', fontSize: '24px', position: 'absolute', top: '-30px', right: '13px' }}><RightOutlined /></div>
            </div>
        );
    };

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
                <div style={{ color: '#000', fontSize: '24px', position: 'absolute', top: '-30px', left: '13px' }}>
                    <LeftOutlined />
                </div>
            </div>
        );
    };
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 551,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const settingsDinning = {
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 7,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 551,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const items = categoryList && categoryList.map((category, index) => ({
        key: `${index + 1}`,
        label: category.name,
        children: (
            <div className="SubCategory">
                {category?.child_items && category?.child_items.map((child, childIndex) => (
                    <span key={childIndex} onClick={() => handleSpanClick(childIndex)} className={activeSpan === childIndex ? 'active' : ''}>
                        <p onClick={() => handleItemClick(child?.slug, menuDetails?.name, menuDetails?.slug, category?.name, category?.slug)}>{child.name}</p>
                        {/* <Checkbox checked={checkedIds.includes(child.id)} onChange={() => onCheckbox(child.id, child.child_name)}>{child.child_name}</Checkbox> */}
                    </span>
                ))}

                <Link to={`${Routes.ProductCategory}/${category.slug}`} state={{ from: slug, menuSlug: slug }} className='all_viewBtn'>View All</Link>
            </div>
        ),
    }));




    const [active, setActive] = useState(false);
    const handleActiveChange = (checked) => {
        setActive(checked);
    };

    return (
        <>
            <div className="drink-category-container">
                <div className="drinkware_top_img_sec">
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        {menuDetails?.assign_slider !== null ? (
                            menuDetails?.assign_slider?.page_slider?.page_slider_images.map((slider, index) => (
                                <Carousel.Item key={index}>
                                    <img src={`${sliderPath}${slider.image}`} alt={slider.image_title} />
                                    <Carousel.Caption>
                                        <div className='giftly_heading_para'>
                                            <h1>{slider.image_title}</h1>
                                            <p>{slider.description}</p>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))
                        ) : (
                            <Carousel.Item>
                                {menuDetails && menuDetails.image ? (
                                    <img src={`${path}${menuDetails?.image}`} alt='' />
                                ) : (
                                    <Skeleton.Image active={active} height={300} width={'100%'} />
                                )}
                                <Carousel.Caption>
                                    {menuDetails ? (
                                        <>
                                            <div className='giftly_heading_para'>
                                                <h1>{menuDetails.name}</h1>
                                                <p>{menuDetails.description}</p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Skeleton height={40} width={'80%'} />
                                            <Skeleton height={20} width={'60%'} />
                                        </>
                                    )}
                                </Carousel.Caption>
                            </Carousel.Item>
                        )}
                    </Carousel>
                </div>
                <div className="path_drinkware">
                    <div className='path_linkedCont'>  <Link to={Routes?.Home}> Home</Link> <LiaGreaterThanSolid /> <p>{menuDetails?.name}</p></div>
                </div>

                {/* <div className='dinner_slider_diining'>
                    <Slider {...settingsDinning} infinite={list.length >= 7 ? true : false} >
                        <div key={index}>
                            <div className="item" onClick={() => handleItemClick(items?.categories?.slug, menuDetails?.name, menuDetails?.slug)}>
                                <div className="img-wrapper" >
                                    <img src={ProductSliderImg} alt="" />
                                </div>
                                <div className="overllay-text">
                                    <h3>Bottle</h3>
                                </div>
                            </div>
                        </div>
                        <div key={index}>
                            <div className="item" onClick={() => handleItemClick(items?.categories?.slug, menuDetails?.name, menuDetails?.slug)}>
                                <div className="img-wrapper" >
                                    <img src={ProductSliderImg1} alt="" />
                                </div>
                                <div className="overllay-text">
                                    <h3>bowl</h3>
                                </div>
                            </div>
                        </div>
                        <div key={index}>
                            <div className="item" onClick={() => handleItemClick(items?.categories?.slug, menuDetails?.name, menuDetails?.slug)}>
                                <div className="img-wrapper" >
                                    <img src={ProductSliderImg2} alt="" />
                                </div>
                                <div className="overllay-text">
                                    <h3>mug</h3>
                                </div>
                            </div>
                        </div>
                        <div key={index}>
                            <div className="item" onClick={() => handleItemClick(items?.categories?.slug, menuDetails?.name, menuDetails?.slug)}>
                                <div className="img-wrapper" >
                                    <img src={ProductSliderImg3} alt="" />
                                </div>
                                <div className="overllay-text">
                                    <h3>cup</h3>
                                </div>
                            </div>
                        </div>
                        <div key={index}>
                            <div className="item" onClick={() => handleItemClick(items?.categories?.slug, menuDetails?.name, menuDetails?.slug)}>
                                <div className="img-wrapper" >
                                    <img src={ProductSliderImg4} alt="" />
                                </div>
                                <div className="overllay-text">
                                    <h3>Cup</h3>
                                </div>
                            </div>
                        </div>
                        <div key={index}>
                            <div className="item" onClick={() => handleItemClick(items?.categories?.slug, menuDetails?.name, menuDetails?.slug)}>
                                <div className="img-wrapper" >
                                    <img src={ProductSliderImg5} alt="" />
                                </div>
                                <div className="overllay-text">
                                    <h3>Image</h3>
                                </div>
                            </div>
                        </div>
                        <div key={index}>
                            <div className="item" onClick={() => handleItemClick(items?.categories?.slug, menuDetails?.name, menuDetails?.slug)}>
                                <div className="img-wrapper" >
                                    <img src={ProductSliderImg6} alt="" />
                                </div>
                                <div className="overllay-text">
                                    <h3>Cup</h3>
                                </div>
                            </div>
                        </div>
                        <div key={index}>
                            <div className="item" onClick={() => handleItemClick(items?.categories?.slug, menuDetails?.name, menuDetails?.slug)}>
                                <div className="img-wrapper" >
                                    <img src={ProductSliderImg7} alt="" />
                                </div>
                                <div className="overllay-text">
                                    <h3>Bottle</h3>
                                </div>
                            </div>
                        </div>
                        <div key={index}>
                            <div className="item" onClick={() => handleItemClick(items?.categories?.slug, menuDetails?.name, menuDetails?.slug)}>
                                <div className="img-wrapper" >
                                    <img src={ProductSliderImg8} alt="" />
                                </div>
                                <div className="overllay-text">
                                    <h3>Bottle</h3>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div> */}

                {isProLoading ? (
                    Array(8).fill(0).map((item, index) => (
                        <div key={index} className="noDataCont">
                            <Skeleton.Image active={true} className='pro-sk-img' />
                        </div>
                    ))
                ) : (
                    productList.length > 0 ?
                        (<div className="drinkware_acc_carousel_section">
                            {/* {isFilterVisible && ( */}
                            <div className="accordian_section">
                                <span>Categories : </span>
                                <div className="titles_acd">
                                    <Collapse onChange={onChange} items={items}
                                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />} />
                                </div>
                                <Link to={`${Routes?.AllItems}/${slug}`} className="all_ItemBtn">
                                    All Items
                                </Link>
                            </div>
                            <div className="right-image-glry">
                                {productList?.map((productMenu, index) => (
                                    // productMenu?.images?.length >0 &&()
                                    <React.Fragment key={index}>

                                        <div className="img-box2nd" onClick={() => handleItemClick(productMenu?.slug, menuDetails?.name, menuDetails?.slug)}>
                                            {productMenu?.images?.[0]?.image ? (
                                                <Carousel>
                                                    <Carousel.Item>
                                                        <img src={`${path}${productMenu.images[0].image}`} alt='' />
                                                        <Carousel.Caption>
                                                            <div className='giftly_heading_para'>
                                                                <h3>{productMenu?.name}</h3>
                                                                <p>{productMenu?.description}</p>
                                                            </div>
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                </Carousel>
                                            ) : null}
                                        </div>
                                        {productMenu?.product_datas?.length > 0 &&
                                            <div className='dinner_slider'>
                                                <Slider {...settings}>
                                                    {productMenu?.product_datas?.map(productData => (
                                                        <div className='dinner_sliderImg' key={productData?.id}>
                                                            <div className='dinnerCategory_cont'>
                                                                <Link to={`${Routes.ProductDetail}/${productData.product_slug}`} state={{ from: menuDetails.name, menuSlug: menuDetails?.slug, route: productMenu?.name, categorySlug: productMenu?.slug }}>
                                                                    <div className='imageContainer '>
                                                                        <img src={productData?.productimages?.length > 0 ? `${productPath}${productData?.productimages[0]?.file_name}` : img4} alt='' />
                                                                        <img src={!productData.productimages[1] ? img4 : `${productPath}${productData.productimages[1]?.file_name}`} className='hoverImage' alt='' />
                                                                    </div>
                                                                </Link>
                                                                {productData?.stock_quantity <= 0 ?
                                                                    <div className="sold_off_chips">
                                                                        <p>Sold Out</p>
                                                                    </div> :
                                                                    productData?.discount > 0 && productData?.discount !== null &&
                                                                    <div className="off_chips">
                                                                        <p>{productData?.discount}% off</p>
                                                                    </div>}
                                                                <Wishlist is_wishlist={productData?.is_wishlist} pId={productData?.id} path={`${Routes.ProductDetail}/${productData?.product_slug}`} mode='HeartOutlined' />
                                                                <AddToCart productList={productData} p_id={productData?.id} imgPath={productPath} />
                                                            </div>
                                                            <Link Link to={`${Routes.ProductDetail}/${productData.product_slug}`} state={{ from: menuDetails.name, menuSlug: menuDetails?.slug, route: productMenu?.name, categorySlug: productMenu?.slug }}>
                                                                <div className='dinnerSlider_details'>
                                                                    <p>{productData.product_name}</p>
                                                                    <div className='dinnerSliderSpan'>
                                                                        <ProductPrice product={productData} />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        }
                                    </React.Fragment>
                                ))}
                            </div>
                        </div >) : (<div className='noDataCont'>
                            <img src={NoDataimg} alt='' />
                            <h5>Coming Soon</h5>
                        </div>))
                }
            </div >
        </>
    )
}

export default DiningPlates