import React, { useEffect, useState } from 'react';
import { Drawer, Radio, Checkbox, Tooltip, Modal, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FaGreaterThan } from 'react-icons/fa6';
import frame from '../../assets/images/Frame.png';
import DrawerCuppon from './DrawerCuppon';
import ProceedSection from './ProceedSection';
import DrawerCartProduct from './DrawerCartProduct';
import Routes from '../../Routes/Routes';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getCouponList } from '../../services/coupon/Coupon';
import NoCartimg from '../../assets/images/nocart.png'
import { STRINGS } from '../../constants/Constant';
import { addToCartId } from '../../reducers/cartIdSlice';
import CalculateTotals from '../../utils/cartUtils';
import DeleteImg from '../../assets/images/delete.png'
import couponsImg from '../../assets/images/couponsImg.jpg'
import trashLottie from '../../assets/images/trash-lottie.json'
import Lottie from 'react-lottie'
import { addWishlist } from '../../services/wishlist/addWishlist';
import { addToCart, clearCart } from '../../reducers/cartSlice';
import { deleteCart, updateCartStatus } from '../../services/cart/addCart';
import { applyReward } from '../../reducers/rewardSlice';
import { applyCoupon } from '../../reducers/couponSlice';
import { getRewardsPoints } from '../../services/rewardpoints/getRewards';
import GstInfo from '../GstInfo';
import { clearUser } from '../../reducers/userSlice';
import { CloseOutlined } from '@ant-design/icons';

const DrawerCart = ({ onClose, openDrawer, setOpenDrawer }) => {
  const { total = { totalComparePrice: 0, totalCount: 0, totalPrice: 0 }, disAmount = 0, grandTotal = 0, bagDiscount, firstOrderCouponDiscount = 0 } = CalculateTotals();
  // console.log(firstOrderCouponDiscount,'firstOrderCouponDiscount,total.totalComparePrice');
  const [initialCoupons, setInitialCoupons] = useState([]);
  const [openCupponDrawer, setOpenCupponDrawer] = useState(false);
  // let cart = JSON.parse(localStorage.getItem('cart')) || [];
  const cart = useSelector((state) => state.cart);
  const totalProductCount = cart.reduce((total, item) => total + item.count, 0);
  const cartIds = useSelector((state) => state.cartId);
  const couponSlice = useSelector((state) => state.coupon);
  const dispatch = useDispatch();
  const [rewardPointsChecked, setRewardPointsChecked] = useState(false);
  // console.log(rewardPointsChecked,'rewardPointsChecked')
  const [points, setPoints] = useState(null);
  const [rewardPoints, setRewardPoints] = useState()
  // const [selectAllChecked, setSelectAllChecked] = useState(true);
  let firstOrderCouponDetail = localStorage.getItem('FirstOrderCouponDetails');
  const excRate = useSelector(state => state.excrate);

  firstOrderCouponDetail = JSON.parse(firstOrderCouponDetail);
  // console.log('firstOrderCouponDetaijjl===',firstOrderCouponDetail);




  const handleRewardPointsChange = (e) => {
    if (cartIds.length > 0) {
      setRewardPointsChecked((prevChecked) => !prevChecked);
      const roundPoints = rewardPoints?.total_points / STRINGS.REWARD_CAL;
      const pointsToApply = rewardPointsChecked ? null : roundPoints;
      let adjustedPoints = pointsToApply;
      if (pointsToApply !== null && pointsToApply > grandTotal) {
        const extraPoints = pointsToApply - grandTotal; // Calculate extra points
        adjustedPoints = pointsToApply - extraPoints - 1; // Calculate adjusted points
      }
      setPoints(adjustedPoints);
      // Dispatch applyReward action with the points
      dispatch(applyReward(adjustedPoints));
    } else {
      if (rewardPointsChecked === true) {
        // console.log('Please select product',rewardPointsChecked)
        setRewardPointsChecked((prevChecked) => !prevChecked);
        dispatch(applyReward(''));
        setPoints(null);
      }
      toast.error('Please select product', e);
      dispatch(applyReward(''));
      setPoints(null);
    }
  };
  useEffect(() => {
    if (cartIds.length < 1) {
      setPoints(null);
      dispatch(applyReward(''));
      if (rewardPointsChecked === true) {
        setRewardPointsChecked((prevChecked) => !prevChecked);
        setPoints(null);
      }
    }
  }, [cartIds])

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();


  useEffect(() => {
    const handleRewardPoints = async () => {
      try {
        const response = await getRewardsPoints(user.token);
        if (response?.status === '1') {
          setRewardPoints(response?.data)
        }
      } catch (error) {
        if (error.message === "Invalid token") {
          // Handle invalid token scenario
          localStorage.removeItem('user'); // Remove only the 'user' key from local storage
          dispatch(clearUser());
          navigate(Routes.SignIn, { state: { from: Routes.Home } }); // Redirect to login page with state
        }
        console.error('Error fetching coupon data:', error);
      }

    }
    if (openDrawer) {
      handleRewardPoints();
    }
  }, [user, openDrawer]);

  const handleCouponClick = async () => {

    if (cart.length > 0) {
      if (user && user.id) {
        setOpenCupponDrawer(true);

        try {
          const response = await getCouponList(user.token);
          const responseData = response?.data || [];
          const transformedCoupons = responseData.map((coupon) => ({
            id: coupon.id,
            coupon_code: coupon.coupon_code,
            coupon_type: coupon.coupon_type,
            type_val: coupon.type_val,
            coupon_desc: coupon.coupon_desc,
            coupon_allow: coupon.coupon_allow,
            applied: couponSlice.id === coupon.id ? true : false,
            removed: false,
            showRemoveConfirmation: false,
            from_date: coupon.from_date,
            to_date: coupon.to_date,
            min_amount: coupon.min_amount,
            upto_amount: coupon.upto_amount
          }));

          setInitialCoupons(transformedCoupons);
          // Extract coupon codes from responseData
          const couponCodes = responseData.map(coupon => coupon.coupon_code);

          // Store all coupon codes in session storage
          sessionStorage.setItem('couponCodes', JSON.stringify(couponCodes));
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          if (error.message === "Invalid token") {
            // Handle invalid token scenario
            localStorage.removeItem('user'); // Remove only the 'user' key from local storage
            dispatch(clearUser());
            navigate(Routes.SignIn, { state: { from: Routes.Home } }); // Redirect to login page with state
          } else {
            console.error('Error adding to wishlist:', error);
            // Handle other errors gracefully
          }
        }
      } else {
        onClose();
        navigate(Routes.SignIn, { state: { from: Routes.Checkout } });
      }
    } else {
      onClose();
      navigate(Routes.Home);
      toast.error(STRINGS.NO_PRODUCT_IN_CART_CONTINUE_SHOPPING);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    if (cartIds.length > 0) {
      setIsModalOpen(true);
    }
  };
  const handleOk = async () => {
    const filteredCart = cart.filter((cartItem) => cartIds.includes(cartItem.id));
    const updatedCart = cart.filter((item) => !cartIds.includes(item.id));
    if (!user.id) {
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      dispatch(addToCart(updatedCart));
    }

    if (filteredCart.length > 0) {
      try {
        const deleteOperations = filteredCart.map(async (product) => {
          const formData = new URLSearchParams();
          formData.append('id', product.id);

          const responseDel = await deleteCart(formData);
          const deleteCartStatus = responseDel.data.status;
          if (deleteCartStatus === "1") {
            return product.id; // Return the ID of the successfully deleted product
          } else {
            return product.id;
          }
        });

        const deletedProductIds = await Promise.all(deleteOperations);

        // Update local storage and state after all delete operations are completed
        const updatedCart = cart.filter((item) => !deletedProductIds.includes(item.id));

        if (updatedCart.length > 0) {
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          dispatch(addToCart(updatedCart));
        } else {
          const couponnull = {};
          dispatch(applyCoupon(couponnull));
          localStorage.setItem('coupon', JSON.stringify(couponnull));
          localStorage.removeItem("cart");
          localStorage.removeItem("shippingFees")
          dispatch(clearCart());
        }

        toast.success(STRINGS.REMOVED_SUCCESSFULLY);
      } catch (error) {
        console.error("Deleting Error:", error);
      }
    }

    setIsModalOpen(false);
  };

  const handleCancel = async () => {
    setIsModalOpen(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: trashLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  useEffect(() => {
    if (cartIds.length === cart.length) {
      if (selectAllChecked === false)
        setSelectAllChecked(true)
    }
  }, [cart, cartIds])

  const [allProductIds, setAllProductIds] = useState([]);

  useEffect(() => {
    const productIds = cart.map((product) => product.id);
    setAllProductIds(productIds);
  }, [cart]);

  useEffect(() => {
    updateCartIds(allProductIds);
  }, [allProductIds]);

  const updateCartIds = (newCartIds) => {
    localStorage.setItem('cartId', JSON.stringify(newCartIds));
    dispatch(addToCartId(newCartIds));
  };
   //handle single id
   const handleUpdateCartStatus = async (pId, isChecked) => {
    try {
      const products = [{
        product_id: pId,
        status: isChecked ? "0" : "1",
      }];

      await updateCartStatus(products, user.token);
    } catch (error) {
      console.error('Failed to update cart status:', error);
    }
  };

  const handleCardClick = (p_id) => {
    const isInCartIds = cartIds.includes(p_id);
    if (isInCartIds) {
      handleUpdateCartStatus(p_id, isInCartIds);
      const filterCartIds = cartIds.filter((cartId) => cartId !== p_id);
      updateCartIds(filterCartIds);
      localStorage.setItem('localCartIds', JSON.stringify(filterCartIds));

      if (filterCartIds.length === 0) {
        setSelectAllChecked(false);
      }
    } else {
      handleUpdateCartStatus(p_id, isInCartIds);
      const updatedCartIds = [...cartIds, p_id];
      updateCartIds(updatedCartIds);
      localStorage.setItem('localCartIds', JSON.stringify(updatedCartIds));
    }
  };
  //
  // const handleCardClick = (p_id) => {
  //   const isInCartIds = cartIds.includes(p_id);
  //   if (isInCartIds) {
  //     handleUpdateCartStatus(p_id, isInCartIds);
  //     const filterCartIds = cartIds.filter((cartId) => cartId !== p_id);
  //     updateCartIds(filterCartIds);
  //     localStorage.setItem('localCartIds', JSON.stringify(filterCartIds));

  //     if (filterCartIds.length === 0) {
  //       setSelectAllChecked(false);
  //     }
  //   } else {
  //     handleUpdateCartStatus(p_id, isInCartIds);
  //     const updatedCartIds = [...cartIds, p_id];
  //     updateCartIds(updatedCartIds);
  //     localStorage.setItem('localCartIds', JSON.stringify(updatedCartIds));
  //   }
  //   //
  //   // Find the cart item and check its current `isChecked` status
  //   const cartItem = cart.find(item => item.id === p_id);
  //   const isChecked = cartItem?.isChecked || false;

  //   // Toggle the `isChecked` status to false
  //   let updatedCart = cart.map(item =>
  //     item.id === p_id ? { ...item, isChecked: !isChecked } : item
  //   );
  //   localStorage.setItem('cart', JSON.stringify(updatedCart));
  //   dispatch(addToCart(updatedCart));
  // };

  //
   //handle multipal ids
   const handleSelectUpdateCartStatus = async (pIds, isChecked) => {
    try {
      const products = pIds.map(pId => ({
        product_id: pId,
        status: isChecked ? "0" : "1",
      }))
      await updateCartStatus(products, user.token);
    } catch (error) {
      console.error('Failed to update cart status:', error);
    }
  };


  const handleSelectAll = (flag) => {
    if (flag) {
      handleSelectUpdateCartStatus(allProductIds, flag);
      updateCartIds([]);
    } else {
      handleSelectUpdateCartStatus(allProductIds, flag);
      updateCartIds(allProductIds);
    }

    setSelectAllChecked((prev) => !prev);
  };
  const [isWishlistClicked, setIsWishlistClicked] = useState(false);

  const handleWishlist = async () => {
    // console.log("call  handleWishlist ");
    if (!isWishlistClicked) { // Check if the wishlist button is not clicked
      setIsWishlistClicked(true); // Set the flag to true to indicate that the button is clicked
      const filteredCart = cart.filter((cartItem) => cartIds.includes(cartItem.id));
      if (filteredCart.length > 0) {
        const wishlistPromises = filteredCart.map(async ({ id, is_wishlist }) => {
          try {
            if (is_wishlist === 0) {
              const formData = new URLSearchParams();
              formData.append('product_id', id);
              const response = await addWishlist(formData, user?.token);
              if (response.status === "1") {
                return 1; // Success
              }
            }
          } catch (error) {
            if (error.message === "Invalid token") {
              // Handle invalid token scenario
              localStorage.removeItem('user'); // Remove only the 'user' key from local storage
              dispatch(clearUser());
              navigate(Routes.SignIn, { state: { from: Routes.Home } }); // Redirect to login page with state
            }
            console.error(`Error ${is_wishlist === 0 ? 'adding to wishlist' : 'handling deleteCart'}:`, error);
          }
          return 0; // Failure
        });

        const results = await Promise.all(wishlistPromises);
        const msgstatus = results.includes(1) ? 1 : 0;

        toast.success(msgstatus === 1 ? "Added to favourite" : "Already added in favourite");
        if (filteredCart.length > 0) {
          try {
            const deleteOperations = filteredCart.map(async (product) => {
              const formData = new URLSearchParams();
              formData.append('id', product.id);

              const responseDel = await deleteCart(formData);
              const deleteCartStatus = responseDel.data.status;

              if (deleteCartStatus === "1") {
                return product.id; // Return the ID of the successfully deleted product
              }
            });

            const deletedProductIds = await Promise.all(deleteOperations);

            // Update local storage and state after all delete operations are completed
            const updatedCart = cart.filter((item) => !deletedProductIds.includes(item.id));

            if (updatedCart.length > 0) {
              localStorage.setItem('cart', JSON.stringify(updatedCart));
              dispatch(addToCart(updatedCart));
            } else {
              const couponnull = {};
              dispatch(applyCoupon(couponnull));
              localStorage.setItem('coupon', JSON.stringify(couponnull));
              localStorage.removeItem("cart");
              localStorage.removeItem("shippingFees")
              dispatch(clearCart());
            }
          } catch (error) {
            console.error("Deleting Error:", error);
          }
        }
      }
      setIsWishlistClicked(false);
    }
  };
  //
  const closeDrawerCart = (e) => {
    const className = e?.target?.className;
    setOpenCupponDrawer(false)
    if (className === undefined) {
      onClose();
    }
  };
  const [showGSTForm, setShowGSTForm] = useState(false);
  const isINR = excRate?.currencyCode === "INR";
  const currencySymbol = excRate?.currencySymbol || excRate?.currencyCode || '$';
  return (
    <>
      <Drawer
        title={`Your Cart (${totalProductCount})`}
        placement='right'
        onClose={onClose}
        open={openDrawer}
        width={600}
        className="addToCart_drawer"
      >
        {cart.length === 0 ? (
          <div className='noDataCont' onClick={onClose}>
            <img src={NoCartimg} alt='' />
            <h5> No Product In cart.</h5>
            <Link to={Routes.Home}>Continue Shopping</Link>
          </div>
        ) : (
          <div className='drawer_cupns_space'>
            <div className='sticky_div'>

            {firstOrderCouponDetail?.order_count === 0 && (
              <div className='img_fixed'>
                <img src={couponsImg} alt="couponsImg" className='couponsImg' />
                <div className='coupon_number'>
                {firstOrderCouponDetail?.data?.type_val}
                {firstOrderCouponDetail?.data?.coupon_type === 'percent' ? '%' : firstOrderCouponDetail?.data?.coupon_type} <br />Off
                </div>
                <div className='coupons_details'>
                  <h4>FIRSTBUY</h4>
                  <span>Get a special discount with our First Buy Coupon! Shop now and save on your first purchase!</span>
                </div>
                {/* <Tooltip title="Delete Coupon" placement="bottom">
                  <div className='close_iocn'>
                    <CloseOutlined />
                  </div>
                </Tooltip> */}
              </div>   )}
            </div>
            <div className='drwawer_margins'>
              {user?.id &&
                rewardPoints?.total_points >= 100 && (
                  <div className='coupan_area'>
                    <div className='copan_left'>
                      <Radio checked={rewardPointsChecked} onClick={handleRewardPointsChange} />
                      <p>{rewardPoints?.total_points} Reward Points</p>
                    </div>
                    <div className='copan_right'>
                      <button onClick={handleRewardPointsChange}> {points !== null ? 'Remove' : 'Apply'}</button>
                    </div>
                  </div>
                )
              }

              <div className='select_allProduct'>
                <div className='selected_container'>
                  <Checkbox checked={cartIds && cartIds.length > 0 && selectAllChecked} onChange={() => handleSelectAll(selectAllChecked)} />
                  <span>{`${cartIds.length}/${cart.length} Items Selected`}</span>
                </div>
                <div className='icons_selected'>
                  {/* <Tooltip title="Move To Favourite">
                    <HeartOutlined onClick={handleWishlist} />
                  </Tooltip> */}
                  <Tooltip title="Delete Item">
                    <img src={DeleteImg} onClick={showModal} alt='' />
                  </Tooltip>
                </div>
              </div>
              {cart.map((product, index) => (
                //  console.log('selectAllChecked:', selectAllChecked),
                <DrawerCartProduct
                  key={index}
                  product={product}
                  onClose={onClose}
                  handleCardClick={handleCardClick}
                  checked={selectAllChecked}
                  setSelectAllChecked={setSelectAllChecked}
                  onChange={handleSelectAll}
                />
              ))}
              <div className='coupan' onClick={handleCouponClick}>
                <div className='left_Coupon'>
                  <img src={frame} alt='img' />
                </div>
                <div className='right_Coupon'>
                  <p>Check Out More Vouchers</p>
                </div>

                <div className='icon_nex_cupn'>
                  <FaGreaterThan />
                </div>
              </div>
              <div className='prize_detail'>
                <h4>Price Details</h4>
                <div className='prize_dra'>
                  <p>Bag MRP {`(${total.totalCount} items)`}</p>
                  <p>{isINR ? "₹" : currencySymbol} {total.totalComparePrice.toFixed(2)}</p>
                </div>
                <div className='prize_dra'>
                  <p>Kairaus Savings</p>
                  <p>{isINR ? "- ₹" : `- ${currencySymbol}`} {bagDiscount ? `${bagDiscount.toFixed(2)}` : 0}</p>
                </div>
                <div className='prize_dra' onClick={handleCouponClick}>
                  <div className='extra_coupons'> <p>Coupon Discount</p><span>({couponSlice?.coupon_code})</span></div>
                  {disAmount > 0 ? (
                    <p> {isINR ? "- ₹" : `- ${currencySymbol}`}{disAmount.toFixed(2)}</p>
                  ) : (

                    <span onClick={handleCouponClick}>Apply Coupon</span>

                  )}
                </div>
                <div className='prize_dra'>

                  {firstOrderCouponDetail?.order_count === 0 && (
                    <>
                      <p>
                        First Order Discount (
                        {firstOrderCouponDetail?.data?.type_val}
                        {firstOrderCouponDetail?.data?.coupon_type === 'percent' ? '%' : firstOrderCouponDetail?.data?.coupon_type})
                      </p>
                      <p>
                        {isINR ? "- ₹" : `- ${currencySymbol}`}
                        {firstOrderCouponDiscount.toFixed(2)}
                      </p>
                    </>
                  )}

                </div>
                {/* <div className='prize_dra'>
                  <p>Shipping</p>
                  <p>Free</p>
                </div> */}
                {
                  points !== null && (
                    <div className='prize_dra'>
                      <p>Reward Points Discount</p>
                      <p>-₹{points}</p>
                    </div>
                  )

                }
                <div className='prize_dra'>
                  <p className='p_bold'>Total</p>
                  <p className='p_bold'>{isINR ? "₹" : currencySymbol} {grandTotal.toFixed(2)}</p>
                  {/* <p className='p_bold'>₹{FirstOrderCouponDiscount(grandTotal.toFixed(2))}</p> */}
                </div>

              </div>
              <div className='reward_point_drawer'>
                <div className='reward_text_p'>
                  <p>Redeem points on your next order!</p>
                  <p className='bold_p_reward'>Earn {grandTotal.toFixed(0)} Reward Points</p>
                </div>
              </div>
              {/* <GstInfo showGSTForm={showGSTForm} setShowGSTForm={setShowGSTForm} /> */}
            </div>

            <ProceedSection grandTotal={grandTotal} onClose={onClose} showGSTForm={showGSTForm} isINR={isINR} currencySymbol={currencySymbol} />

          </div>
        )}
      </Drawer>
      <DrawerCuppon drawerClose={closeDrawerCart} openCupponDrawer={openCupponDrawer} grandTotal={grandTotal} initialCoupons={initialCoupons} disAmount={disAmount} />
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className='deletePopUp_model'
        footer={
          <div className='custom-modal-footer'>
            <Button key="cancel" onClick={handleCancel} className='cancel_Button'>
              Cancel
            </Button>
            <Button key="delete" onClick={handleOk} className='deleteButton'>
              Delete
            </Button>
          </div>
        }
      >
        <div className='delete_popUpDesign'>
          <p>Are you sure want to delete the {cart?.length > 1 ? "products" : "product"}?</p>
          <Lottie options={defaultOptions}
            max-height={200}
            width={250}
          />

        </div>
      </Modal>
    </>
  );
};

export default React.memo(DrawerCart);
