import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

const ProductPrice = ({ product }) => {
    const excRate = useSelector(state => state.excrate) || {};
    const { price = 0, compare_price = 0, usd_price = 0, usd_compare_price = 0, newPrice = 0, count = 1 } = product || {};

    // Compute values
    const updatePrice = newPrice || price;
    const isINR = excRate.currencyCode === "INR";
    const showComparePrice = compare_price > 0 && parseFloat(price) !== parseFloat(compare_price);
    const rate = excRate.rate || 1;
    const convertedPrice = usd_price * rate;
    const convertedComparePrice = usd_compare_price * rate;
    const currencySymbol = excRate.currencySymbol || excRate.currencyCode;

    // Format prices
    const formattedPrice = isINR ? `₹${(updatePrice * count).toFixed(2)}` : `${currencySymbol} ${(convertedPrice * count).toFixed(2)}`;
    const formattedComparePrice = isINR ? `₹${(compare_price * count).toFixed(2)}` : `${currencySymbol} ${(convertedComparePrice * count).toFixed(2)}`;
    if (currencySymbol === undefined) {
        return <Spin />;
    }
    return (
        <>
            <div className='d-flex gap-2 align-items-center'>
                {showComparePrice && <p>{formattedComparePrice}</p>}
                <span>{formattedPrice}</span>
            </div>
        </>
    );
}

export default memo(ProductPrice);
