import React from 'react';
import { Link } from 'react-router-dom';
import dImg from '../../assets/images/d.png'

const Category = ({ categoryList, imagePath }) => {
  // console.log('categoryList',categoryList);
  // Check if categoryList is undefined or empty
  if (!categoryList || categoryList.length === 0) {
    return null; // or you can render a loading indicator or an error message
  }

  return (
    <section className="categ_main">
      <div className="shop_by_categ row mx-0">
        <h4>Shop By Categories</h4>
        <div className="col-md-4 col-sm-6 col-12 img_flex">
          {categoryList.slice(0, 2).map((category) => (
            <Link key={category?.menus?.id} to={category?.menus?.slug} className='img_decor'>
              <img
                src={category?.menus?.image ? imagePath + category?.menus?.image : dImg}
                alt={category?.menus?.name}
              />
            </Link>
          ))}
        </div>
        <div className="col-md-4 col-sm-6 col-12 img_flex">
          {categoryList.slice(2, 3).map((category) => (
            <Link key={category?.menus?.id} to={category?.menus?.slug} className='img_decor'>
              <img
                src={category?.menus?.image ? imagePath + category?.menus.image : dImg}
                alt={category?.menus?.name}
              />
            </Link>
          ))}
        </div>
        <div className="col-md-4 col-sm-6 col-12 img_flex">
          {categoryList.slice(3).map((category) => (
            <Link key={category?.menus?.id} to={category?.menus?.slug} className='img_decor'>
              <img
                src={category?.menus?.image ? imagePath + category?.menus?.image : dImg}
                alt={category?.menus?.name}
              />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Category;
