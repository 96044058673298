import React from 'react';
import { TruckOutlined } from '@ant-design/icons';
import Dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const EstimatedDelivery = ({ supportData }) => {
    // console.log()
    const bufferDays = parseInt(localStorage.getItem("bufferdays"), 10) || 0;
    const productBufferDays = supportData?.product_buffer_days || 0;
    // console.log('supportData vivek',productBufferDays);
    const estimatedDays = bufferDays + productBufferDays;
    const comp = useSelector(state => state.shippingFees);

    const dateFormat = 'MMM DD, YYYY';
    const originalDate = Dayjs(comp, dateFormat);
    const newDate = originalDate.add(estimatedDays, 'day');
    const newDateString = newDate.isValid() ? newDate.format(dateFormat) : comp;
    // console.log('supportData vivek',comp);

    return (
        <h5 className='pinCode_delivery'>
            <p>  <TruckOutlined /> Estimated delivery: {newDateString}</p>
        </h5>
    );
};

export default EstimatedDelivery;
