export const STRINGS = {
    LOGGED_IN_SUCCESSFULLY :'Logged in successfully!',
    MOVED_TO_CART_SUCCESSFULLY: 'Moved to cart Successfully',
    ALREADY_ADDED_IN_CART : 'All ready added in cart',
    ADDED_IN_CART : 'Added in cart',
    NO_PRODUCT_IN_CART_CONTINUE_SHOPPING: 'No Product In Cart, Continue Shopping!',
    COUPON_NOT_VALID : 'Coupon not valid',
    ADDRESS_ADDED_SUCCESSFULLY:'Address added successfully!',
    ADDRESS_UPDATED_SUCCESSFULLY:'Address updated successfully!',
    FAILED_TO_ADD_ADDRESS:'Failed to add address',
    FAILED_TO_UPDATE_ADDRESS: 'Failed to update address',
    ADDRESS_REMOVED_SUCCESSFULLY:'Address removed successfully!',
    ADDRESS_NOT_REMOVED:'Address was not removed!',
    PROFILE_UPDATED_SUCCESSFULLY:'Profile Updated successfully!',
    RECORD_UPDATED_SUCCESSFULLY:'Record Updated successfully!',
    LOGIN_FAILED_CHECK_CREDENTIALS :'Login failed. Please check your credentials and try again.',
    PLEASE_TRY_AGAIN_LATER: 'Please Try Again Later',
    FAILED_TO_ADD :'Failed to add',
    MOVE_TO_WISHLIST :'Favourite',
    REMOVE_FROM_WISHLIST :'Remove from favourite',
    REMOVED_SUCCESSFULLY :'Removed successfully',
    NOT_REMOVED_SUCCESSFULLY :'Not removed successfully',
    SUBSCRIBE_US: 'Thank you for subscribing to Kairaus! Get ready to enjoy an exceptional shopping experience with us.',
    REMOVED_CART_SUCCESSFULLY :'Removed cart successfully',
    FAVOURITE:'Favourite',
    ORDERS_AND_RETURNS: 'My Order',
    CART:'Cart',
    ADD_TO_CART:'Add To Cart',
    OUT_OF_STOCK:'Out_Of_Stock',
    ADDED_TO_CART_SUCCESSFULLY: 'Added to cart successfully',
    MOVE_TO_CART: 'Move To Cart',
    CC_AVENUE_MERCHANT_ID:'2168434',
    COUPON_EXPIRED:'Coupon Expired',
    REWARD_CAL:100, // TOTAL POINT DIVIDED BY REWAED_CAL ex. 5000/100=50
    NOTIFY_ME :'Notify successful'

}